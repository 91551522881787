export const mapInternalsPrefix = "map-internals-";

export const triangleSprintName = `${mapInternalsPrefix}triangle`;

export const coordinateHandleSpriteName = `${mapInternalsPrefix}coordinate-handle`;
export const coordinateHandleLayerId = `${mapInternalsPrefix}coordinate-handles`;
export const coordinateHandleSourceId = coordinateHandleLayerId;

export const waypointSpriteName = `${mapInternalsPrefix}waypoint`;
export const waypointLayerId = `${mapInternalsPrefix}waypoint`;
export const waypointSourceId = waypointLayerId;

export const rotateHandleSpriteName = `${mapInternalsPrefix}rotate-handle`;
export const rotateHandleLayerId = `${mapInternalsPrefix}rotate-handle`;
export const rotateHandleSourceId = rotateHandleLayerId;

export const coordinateDeleteSpriteName = `${mapInternalsPrefix}coordinate-delete`;
export const coordinateDeleteLayerId = `${mapInternalsPrefix}coordinate-delete`;
export const coordinateDeleteSourceId = coordinateDeleteLayerId;

export const continueDrawingSpriteName = `${mapInternalsPrefix}continue-drawing-button`;
export const continueDrawingLayerId = `${mapInternalsPrefix}continue-drawing-button`;
export const continueDrawingSourceId = continueDrawingLayerId;

export const midpointHandleSpriteName = `${mapInternalsPrefix}coordinate-mid`;
export const midpointHandleLayerId = `${mapInternalsPrefix}coordinate-mid`;
export const midpointHandleSourceId = midpointHandleLayerId;

export const measurementLayerId = `${mapInternalsPrefix}measurement`;
export const measurementSourceId = measurementLayerId;
export const longLastingMeasurementLayerId = `${mapInternalsPrefix}lasting-measurement`;
export const longLastingMeasurementSourceId = longLastingMeasurementLayerId;

export const commentsSpriteName = `${mapInternalsPrefix}comments`;
export const commentsLayerId = `${mapInternalsPrefix}comments`;
export const commentsSourceId = commentsLayerId;

export const textboxSpriteName = `${mapInternalsPrefix}textbox`;

export const sdfDisabledSuffix = "sdf-disabled";

export const internalMapLayerIds = [
    coordinateDeleteLayerId,
    rotateHandleLayerId,
    coordinateHandleLayerId,
    midpointHandleLayerId,
    continueDrawingLayerId,
    measurementLayerId,
    longLastingMeasurementLayerId,
    waypointLayerId,
];

export const traceLineLayerId = `${mapInternalsPrefix}trace-line`;
export const traceLineSourceId = traceLineLayerId;
export const localSuffix = "local";

export const highlightInfix = "highlight";

export const previewLayerId = "preview-layer";

export const areaSelectLayerId = "area-select";
export const selectedLayerAreaSelectId = "selected-layer-area-select";

export const analysisLayerIdPrefix = "analysis";

// Analysis Lines
export const analysisLineLayerId = `${analysisLayerIdPrefix}-linestring`;
export const analysisLineSourceId = analysisLineLayerId;

// Analysis Polygons
export const analysisPolygonLayerId = `${analysisLayerIdPrefix}-polygon`;
export const analysisPolygonSourceId = analysisPolygonLayerId;

export const analysisLayerIds = [analysisLineLayerId, analysisPolygonLayerId, longLastingMeasurementLayerId];
export const analysisSourceIds = [analysisLineSourceId, analysisPolygonSourceId, longLastingMeasurementSourceId];

export const analysisMeasuringLabelLayerName = "analysis-measuring-label";

/** Layer ids which are used for area search layer, used to exclude from the undo/ redo stack */
export const systemLayerIds = [areaSelectLayerId, selectedLayerAreaSelectId];

export const MEASUREMENT_PADDING = 40;

export const MAPBOX_MAX_ZOOM = 24;
export const MAPBOX_MIN_ZOOM = 0;
// Frame time for 60 frames per second
export const MAP_FRAME_TIME = 16.6;

export const PredefinedZoomLevels = {
    max: MAPBOX_MAX_ZOOM,
    street: 16,
    city: 12,
    country: 6,
    world: 3,
};

export const MAP_OBJECT_DEFAULT_NAME = "Map object";

export const FROM_MAP_ENGINE = "map-engine";

/** List of background layer ids for buildings */
export const buildingLayerIds = ["Building", "building", "OS/TopographicArea_2/Building/1"];

/** List of background layer ids for 3D buildings */
export const building3dLayerIds = ["Building 3D", "building-3d", "building 3d", "OS/TopographicArea_1/Building/1_3D"];

/** The sort key for ordering features on the comments layer */
export const COMMENTS_OBJECT_SORT_PROPERTY = "order";

/** the style value for comment objects to show the default comment icon */
export const DEFAULT_COMMENT_ICON_VALUE = "comments-default";

/** Image for the default comment icon rendered on the map */
export const COMMENT_ICON_DATA_URL =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABLVJREFUaEPtmt9rW2UYxz9nborzFyr4L3g1hsLmULwojqk3TtGL6VxSvOigCooORUUvvNDpheIERaQIdi1dS9HhNuLsjRR1F93WBN0mbNPeVEya9MeS5UebHPmG983enKZNrTU9AQ+8pJwm5/1+nud5fz3P8fgXV5x9N3l4O8G738ffCjzc6HE+ZOapTFylNDrA5U8/YywFlAOtAviB1lSd1/QbDb4QJ/oY+M8Bu1fz+zJ+apriwEMMvgvMAwvm00IJREC69PeS1z8CSBB5xoe3gbtXIzz4Gx/mpykc6WDwdaAIlBwgAbheadjligAS7N3is/ED8B9ZC+HBZ5TxpxOk3+rkxFGgYGDkGdcjDb3RFOAs0a4N+J//F8KDz8yQP9bB0ItA3jR5ROElkIYhtSzAOJGDHrzWCvG2jyylXx5g4CkgZyDkEeuNRRBLAiSIfOLDC60Ub/vKs3BxB/2PA1eAqwbEhagN7IYA62H5oKHmKJ57kCN7gDnHG3bGstMtiwASRLv8FsV8M+9Okjv5KMMHgNkAhMZEFaIOIEHnFp9KotmDW/n/75l48wA/aHayEO6YqAcYJxLzllhNWyna7atEZW4bhzV9TzsQWjM0O1VqHjCLVN96CV2u38vMfvsER98xEBoTGtiaYss1gDiRC2u1wq61ESr4C/fQuwtIGwjNTtVQqgKYvY3iLLRXnKmhCCc+MhAzZlCXDEDkm9VuzFpFXKQ8s50+LXBTQMasEXlvjK7Nmyho1Qv9FSX27DjJS64XvASR3T7IA6G/xkl+HSV2yPWCN070fQ//1dCrr8ZN4VIHg88DOhBpQM96cSKxpU5SYYMqUc5uo28vkDRemBHAsieesEFs5SsNZAHIC9PtCKANngVItyOAQkjWF0RbAuxzAKba0QMuwP8eaOkkVaSc207f/rYdAynyf+xk6I22BTjFZGw/I186AJm2GsRPcuzli2QmDICm0vYByLMwt4P+l8wWQuLV2mcr8SOT33Uz0usA1DZzod8LlfHL99LbbY6T1vo65F9pizFwluRoJ7EecxITgE5l1TRL6AEKlHP30feK4t0Irz9Shn073cOvPYc4PWqsL/GK/WuH+jADnCc9tofjSu1LsIQvTquEFeAvchO7GH7PxLoGrBVfn9gKI0CG/J8dDCkTpwSWrK80SuPUYtgAjOUPAlnH+q54N7lLqGahc6THnub4FybrpmlSTR7Qp4DqMtPV9HoYPKCpsp/z/R9z5pQRr9CRaMX78gWO9QTQCpsg9VMnscOmjKQMocSrSbisrnvBOtm1EtN6AGhjdobkz92MDJuSqqqSEqom0bbpvmoBSxf5WgGQYz6bZT71O7MXPuT0yd/IaFBKlMTJusr3q1kICdd9W/i2RW+dAOv2bspO3wXcAdwJ3A7cAlwPXAeLa2hNzpD24epQdSxrOXvfvkJgxVsAWxcOCl9RoVuibwVuM+I3AxsNgPQ2LYYbKInTd20F0VqtWgoy9+09WdY2QdjmvmpQK+Qt976EOrwZuBGQcH1a629Y5YndAgvEVthdKOsZecFttiLv/mZRyAQ1qbMbgE1Ok/UlfqWWd58Z/E3w9RkbWsFXbVzRK3pLxXb6N5eFf/TD8n5rAAAAAElFTkSuQmCC";

/** Id of the vector source of the comments */
export const COMMENT_VECTOR_SOURCE_ID = "iventis-comments";

/** Black hatched pattern icon value */
export const hatchedPatternBlack2 = "hatched-pattern-black-2";
export const hatchedPatternBlack6 = "hatched-pattern-black-6";
export const hatchedPatternBlack12 = "hatched-pattern-black-12";

/** Red hatched pattern icon value */
export const hatchedPatternRed2 = "hatched-pattern-red-2";
export const hatchedPatternRed6 = "hatched-pattern-red-6";
export const hatchedPatternRed12 = "hatched-pattern-red-12";

/**
 * Used as a fallback zoom level if the zoom is null in the map state.
 * This is only really used if we switch back from unreal engine
 * while using the fly camera or ThirdPersonCamera currently
 */
export const MAPBOX_FALLBACK_ZOOM = 16;
