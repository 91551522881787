import type { ModelLayerSpecification, Map, ExpressionSpecification } from "@iventis/mapbox-gl";
import type { Feature, Point, Geometry } from "geojson";
import type { MapObjectProperties } from "@iventis/map-types";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { createMappedStyleValue, getStaticStyleValue } from "@iventis/layer-style-helpers";
import type { BehaviorSubject } from "rxjs";
import type { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { EMPTY_GUID } from "@iventis/utilities/src/constants";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import type { ModelDataStore, ModelData } from "../../data-store/model-data-store";
import type { DataFieldListItemStore } from "../../data-store/data-field-list-item-store";
import type { MapboxEngineData, MapModuleLayer, MapState } from "../../types/store-schema";
import { ModelLayer } from "../generic-layers/model-layer";
import { getModelLayerStyle } from "../../utilities/layer.helpers";
import { styleValueToMapboxStyleValue } from "../mapbox/engine-mapbox-helpers";
import type { ModelLayerStyle } from "../../types/models";
import type { StylePropertyToValueMap } from "../../types/style-types";
import { getOnlyNeededStyleChanges } from "../mapbox/model-helpers/model-style-helpers";
import { MapboxLayer } from "./mapbox-layer";

export class MapboxModelLayer<
    TSource extends Feature<Geometry, MapObjectProperties>[] = Feature<Point, MapObjectProperties>[],
    TStyle extends ModelLayerStyle = ModelStyle
> extends ModelLayer<TSource, TStyle> {
    public layerAddedToMap = false;

    /** To align how deckgl handles rotation we need to add this value to any mapbox rotation value */
    private readonly deckGlToMapboxRotationModifier = 180;

    /** Ensures the model will be it's original size */
    private readonly defaultScaleValue = [1, 1, 1];

    private readonly styleChangesAffectingScale: (keyof ModelLayerStyle)[] = ["scale", "length", "width", "height"];

    private readonly styleChangesAffectingModel: (keyof ModelLayerStyle)[] = ["model", "customColour", "colour", "customImage"];

    protected readonly map: Map;

    private getAboveLayerId: (layerId: string) => string;

    private readonly mapboxLayer: MapboxLayer<TSource, ModelLayerSpecification>;

    constructor(
        map: Map,
        layer: MapModuleLayer,
        state$: BehaviorSubject<MapState<MapboxEngineData>>,
        modelDataStore: ModelDataStore,
        dataFieldListItemDataStore: DataFieldListItemStore,
        events: {
            getAboveLayerId: (layerId: string) => string;
        }
    ) {
        super(layer, modelDataStore, dataFieldListItemDataStore, state$);
        this.map = map;
        this.mapboxLayer = new MapboxLayer(map, this.layerId, this.sourceId);
        this.getAboveLayerId = events.getAboveLayerId;
        this.initaliseLayer(layer);
    }

    protected async initaliseLayer(layer: MapModuleLayer) {
        this.addSource();
        await this.loadModel(layer);
        await this.loadDataFieldListItems(layer);
        this.addLayer();
        const features = this.getGeoJson();
        if (features != null) {
            this.updateSource(features);
        }
    }

    protected addLayer() {
        const iventisLayer = this.getIventisLayer();
        const mapboxLayer = this.iventisLayerToMapboxLayer(iventisLayer, this.modelData);
        this.mapboxLayer.addLayer(mapboxLayer, this.getAboveLayerId(this.layerId));
        this.updateMapLevel(this.state$.value.currentLevel);
        if (this.state$.value.datesFilter.value.filter) {
            this.addDateFilter();
        }
        this.layerAddedToMap = true;
    }

    protected removeLayer() {
        this.mapboxLayer.removeLayer();
    }

    protected addSource() {
        this.mapboxLayer.addSource();
    }

    public updateSource(source: TSource): void {
        this.mapboxLayer.updateSource(source);
    }

    protected removeSource(): void {
        this.mapboxLayer.removeSource();
    }

    public hideLayer(): void {
        this.mapboxLayer.hideLayer();
    }

    public showLayer(): void {
        this.mapboxLayer.showLayer();
    }

    public updateMapOrder(aboveLayerId: string): void {
        this.mapboxLayer.updateMapOrder(aboveLayerId);
    }

    public updateMapLevel(level: number): void {
        this.mapboxLayer.updateMapLevel(level);
    }

    public addDateFilter() {
        this.mapboxLayer.addDateFilter();
    }

    public removeDateFilter() {
        this.mapboxLayer.removeDateFilter();
    }

    protected async loadModel(iventisLayer: MapModuleLayer) {
        await super.loadModel(iventisLayer);
        for (const model of this.modelData) {
            // Ensure the layer has not been removed before adding the model
            if (!this.removed && !this.map.hasModel(model.modelId)) {
                this.map.addModel(model.modelId, URL.createObjectURL(model.modelFileBlob));
            }
        }
    }

    public async updateStyle(styleChanges: StylePropertyToValueMap<TStyle>[]) {
        const layer = this.state$.value.layers.value.find((layer) => layer.id === this.layerId);
        await this.loadModel(layer);
        await this.loadDataFieldListItems(layer);

        // Width, height, length and scale all change the same style property in mapbox which is "scale"
        const onlyNeededScaleStyleChanges = getOnlyNeededStyleChanges(styleChanges, this.styleChangesAffectingScale);
        // Model and custom image change the same style property in mapbox which is "model-id"
        const onlyNeededStyleChanges = getOnlyNeededStyleChanges(onlyNeededScaleStyleChanges, this.styleChangesAffectingModel);
        for (const change of onlyNeededStyleChanges) {
            const { styleProperty } = change;
            switch (styleProperty) {
                case "model":
                case "customImage":
                case "customColour":
                case "colour":
                    this.map.setLayoutProperty(this.layerId, "model-id", this.getMapboxModelIdValue(layer));
                    break;
                case "height":
                case "width":
                case "length":
                case "scale":
                    this.map.setPaintProperty(this.layerId, "model-scale", this.getMapboxModelScaleValue(layer, this.modelData));
                    break;
                case "objectOrder":
                    // Do nothing
                    break;
                default:
                    throw new Error(`Style property ${styleProperty.toString()} is not handled`);
            }
        }
    }

    private iventisLayerToMapboxLayer(iventisLayer: MapModuleLayer, modelData: ModelData[]): ModelLayerSpecification {
        return {
            id: iventisLayer.id,
            type: "model",
            paint: {
                "model-scale": this.getMapboxModelScaleValue(iventisLayer, modelData),
                "model-rotation": [
                    0,
                    0,
                    // Gets the rotation out of a map object property, we have to inverse the rotation (Mapbox and DeckGl interpret rotation in different directions) and we add the deckgl rotation modifier
                    ["+", ["*", ["get", "z", ["get", "rotation"]], -1], this.deckGlToMapboxRotationModifier],
                ],
            },
            layout: {
                "model-id": this.getMapboxModelIdValue(iventisLayer),
                visibility: iventisLayer.visible ? "visible" : "none",
                "model-allow-density-reduction": false,
            },
            source: iventisLayer.id,
            metadata: {
                name: iventisLayer.name,
                type: "base",
            },
        };
    }

    private getMapboxModelScaleValue(iventisLayer: MapModuleLayer, modelData: ModelData[]): ModelLayerSpecification["paint"]["model-scale"] {
        const modelStyle = getModelLayerStyle(iventisLayer);
        const useDimensions = modelData.some(this.isModelValidForDimensionalScaling);

        // Uses length, width and height style values to determine the scale of the layer
        if (useDimensions) {
            const { model, height } = modelStyle;
            const isModelDataFieldBased = model.extractionMethod === StyleValueExtractionMethod.Mapped;
            const isDimensionsDataFieldBased = this.isLayerDimensionsBasedOnDataFields(modelStyle);
            const isDimensionsUsingMappedProperty = this.isLayerDimensionsListItemPropertyBased(modelStyle);

            // Dimensions are datafield based but all the values are the same
            if (isDimensionsDataFieldBased && Object.values(height.mappedValues).length === 0) {
                return this.createDimensionalScalingValue(modelStyle);
            }

            if (isModelDataFieldBased || isDimensionsDataFieldBased || isDimensionsUsingMappedProperty) {
                // If model is datafield based, get all list item and datafield ids from the model style value.
                // Otherwise only the dimensions are datafield based so get list item and datafield ids from the height style value. Height, width and length will all use the same datafield and list item ids.
                const listItemIds = this.getListItemsForDimensionalScaling(modelStyle);

                const dataFieldId = isModelDataFieldBased ? model.dataFieldId : height.dataFieldId;

                const expression: ExpressionSpecification = ["case"];
                // For each list item being used, create a scale value
                for (const listItemId of listItemIds) {
                    const scaleValue = this.createDimensionalScalingValue(modelStyle, listItemId);
                    expression.push(["==", ["get", dataFieldId], listItemId], scaleValue);
                }

                // Add a static scale value for all map objects which don't have a list item selected
                const staticScalueValue = this.createDimensionalScalingValue(modelStyle);
                expression.push(staticScalueValue);
                return expression;
            }

            if (!isModelDataFieldBased) {
                return this.createDimensionalScalingValue(modelStyle);
            }

            throw new Error(`Combination of model ${model.extractionMethod} and dimension ${height.extractionMethod} extraction methods not handled`);
        }

        // When a model is using the scale style value to determine the scale of the layer
        const { scale } = modelStyle;

        if (scale == null) {
            return this.defaultScaleValue;
        }

        switch (scale.extractionMethod) {
            case StyleValueExtractionMethod.Static: {
                const scaleNumberValue = getStaticStyleValue(scale);
                return [scaleNumberValue, scaleNumberValue, scaleNumberValue];
            }
            default:
                throw new Error(`${scale.extractionMethod} is not supported for "Scale" style value`);
        }
    }

    private getMapboxModelIdValue(iventisLayer: MapModuleLayer): ModelLayerSpecification["layout"]["model-id"] {
        const { model, customImage, customColour, colour } = getModelLayerStyle(iventisLayer);
        const staticModelId = model.staticValue.staticValue;
        const usingCustomColour = getStaticStyleValue(customColour);
        const customImageId = getStaticStyleValue(customImage);
        const usingCustomImage = customImageId !== EMPTY_GUID && customImage != null;
        const usingDataFieldColourAndModel =
            usingCustomColour && model.extractionMethod === StyleValueExtractionMethod.Mapped && colour.extractionMethod === StyleValueExtractionMethod.Mapped;

        if (usingCustomColour && colour.extractionMethod === StyleValueExtractionMethod.Static && model.extractionMethod === StyleValueExtractionMethod.Static) {
            const colourValue = getStaticStyleValue(colour);
            return this.createModelId(staticModelId, customImageId, colourValue);
        }

        // Datafield based colour for a single model
        if (usingCustomColour && model.extractionMethod === StyleValueExtractionMethod.Static) {
            const listItemIdToModelId: Record<string, string> = {};
            // For each colour being used, create a model id
            for (const [listItemId, colourStyleValue] of Object.entries(colour.mappedValues)) {
                listItemIdToModelId[listItemId] = this.createModelId(staticModelId, customImageId, colourStyleValue.staticValue);
            }
            // If the colour datafield has not got a value selected
            const staticModelIdWithColour = this.createModelId(staticModelId, customImageId, colour.staticValue.staticValue);
            return styleValueToMapboxStyleValue(createMappedStyleValue(staticModelIdWithColour, listItemIdToModelId, colour.dataFieldId));
        }

        // Datafield based colour for an datafield based model
        if (usingDataFieldColourAndModel && model.dataFieldId !== colour.dataFieldId) {
            return this.createModelAndColourExpressionCase(model, colour, customImageId);
        }

        // Datafield based colour and model which use the same datafield
        if (usingDataFieldColourAndModel) {
            const listItemIdToModelId: Record<string, string> = {};
            const staticColourValue = colour.staticValue.staticValue;
            // Get all unique list items used for model and colour datafield styling
            const uniqueListItemIds = [...new Set([...Object.keys(model.mappedValues), ...Object.keys(colour.mappedValues)])];
            for (const listItemId of uniqueListItemIds) {
                // Get model and colour value from mapped values, if not present use static value
                const modelValue = model.mappedValues[listItemId]?.staticValue ?? staticModelId;
                const colourValue = colour.mappedValues[listItemId]?.staticValue ?? staticColourValue;
                listItemIdToModelId[listItemId] = this.createModelId(modelValue, customImageId, colourValue);
            }
            // If the model datafield does not have a value selected
            const staticModelIdWithImage = this.createModelId(staticModelId, customImageId, staticColourValue);
            return styleValueToMapboxStyleValue(createMappedStyleValue(staticModelIdWithImage, listItemIdToModelId, model.dataFieldId));
        }

        // Using static custom colour and data driven model
        if (usingCustomColour && model.extractionMethod === StyleValueExtractionMethod.Mapped) {
            const listItemIdToModelId: Record<string, string> = {};
            const colourStaticValue = getStaticStyleValue(colour);
            // For each model list item create a model id with the image added to it
            for (const [listItemId, modelStyleValue] of Object.entries(model.mappedValues)) {
                listItemIdToModelId[listItemId] = this.createModelId(modelStyleValue.staticValue, customImageId, colourStaticValue);
            }
            // If the model datafield does not have a value selected
            const staticModelWithColour = this.createModelId(staticModelId, customImageId, colourStaticValue);
            return styleValueToMapboxStyleValue(createMappedStyleValue(staticModelWithColour, listItemIdToModelId, model.dataFieldId));
        }

        // Model has a custom image and model is atttribute based
        if (usingCustomImage && model.extractionMethod === StyleValueExtractionMethod.Mapped) {
            const listItemIdToModelId: Record<string, string> = {};
            // For each model list item create a model id with the image added to it
            for (const [listItemId, modelStyleValue] of Object.entries(model.mappedValues)) {
                listItemIdToModelId[listItemId] = this.createModelId(modelStyleValue.staticValue, customImageId);
            }
            // If the model datafield does not have a value selected
            const staticModelIdWithImage = this.createModelId(staticModelId, customImageId);
            return styleValueToMapboxStyleValue(createMappedStyleValue(staticModelIdWithImage, listItemIdToModelId, model.dataFieldId));
        }

        // Model has custom image and only a single model
        if (usingCustomImage) {
            return this.createModelId(staticModelId, customImageId);
        }

        // Has no custom colours or images for models
        switch (model.extractionMethod) {
            case StyleValueExtractionMethod.Static:
                return getStaticStyleValue(model);
            case StyleValueExtractionMethod.Mapped:
                return styleValueToMapboxStyleValue(model);
            default:
                throw new Error(`${model.extractionMethod} is not supported for "Model" style value`);
        }
    }

    /** Checks whether a model can be used for dimension based scale value */
    private isModelValidForDimensionalScaling(model: ModelData) {
        return model.height != null && model.height !== 0 && model.width != null && model.width !== 0 && model.length != null && model.length !== 0;
    }

    /** Will create a scale value for a layer and model combination */
    private createDimensionalScalingValue(style: ModelLayerStyle, listItemId?: string) {
        const { model, width, length, height } = style;

        // Ensure the model has dimension style values, otherwise just return default scale value
        if (width == null || length == null || height == null) {
            return this.defaultScaleValue;
        }

        // Find the model which is being used
        const modelId = model.mappedValues?.[listItemId]?.staticValue ?? model.staticValue.staticValue;
        const selectedModelData = this.modelData.find((modelData) => modelData.baseModelId === modelId);
        const canModelBeScaledByDimensions = this.isModelValidForDimensionalScaling(selectedModelData);

        if (selectedModelData == null) {
            throw new Error(`Model with id "${modelId}" is not loaded.`);
        }

        // Model can't be scaled by dimension, so return scale value so model is original size
        if (!canModelBeScaledByDimensions) {
            return this.defaultScaleValue;
        }

        if (this.isLayerDimensionsListItemPropertyBased(style) && listItemId != null) {
            const listItem = this.dataFieldListItems[height.dataFieldId]?.find((listItem) => listItem.id === listItemId);

            if (listItem == null) {
                // If we can't find list item, it could be because it has been deleted. Therefore set to default scale
                return this.defaultScaleValue;
            }

            // If a property value does not exist, then use the model dimension value
            const heightPropertyValue =
                listItem.propertyValues.find((propertyValue) => propertyValue.propertyId === height.dataFieldListItemPropertyId)?.number ?? selectedModelData.height;
            const widthPropertyValue =
                listItem.propertyValues.find((propertyValue) => propertyValue.propertyId === width.dataFieldListItemPropertyId)?.number ?? selectedModelData.width;
            const lengthPropertyValue =
                listItem.propertyValues.find((propertyValue) => propertyValue.propertyId === length.dataFieldListItemPropertyId)?.number ?? selectedModelData.length;

            return [widthPropertyValue / selectedModelData.width, lengthPropertyValue / selectedModelData.length, heightPropertyValue / selectedModelData.height];
        }

        // Calculate the scale value for each width, length and height of the model
        const widthStyleValue = listItemId ? width.mappedValues[listItemId].staticValue : width.staticValue.staticValue;
        const lengthStyleValue = listItemId ? length.mappedValues[listItemId].staticValue : length.staticValue.staticValue;
        const heightStyleValue = listItemId ? height.mappedValues[listItemId].staticValue : height.staticValue.staticValue;
        return [widthStyleValue / selectedModelData.width, lengthStyleValue / selectedModelData.length, heightStyleValue / selectedModelData.height];
    }

    /**
     * Creates a set of case statments for when a model and colour are both datafield based.
     * This will include:
     * 1. Model and colour both have datafield values selected (every value combination)
     * 2. Model has datafield value selected, colour has not got a datafield value selected
     * 3. Model has not got a datafield value selected, colour does have a datafield value selected
     * 4. Model and colour datafield values are not selected
     */
    private createModelAndColourExpressionCase(modelStyleValue: StyleValue<string>, colourStyleValue: StyleValue<string>, customImageId: string) {
        const expression: ExpressionSpecification = ["case"];
        // Create a model id for each combination of model and colour
        for (const [colourListItemId, colourValue] of Object.entries(colourStyleValue.mappedValues)) {
            for (const [modelListItemId, modelValue] of Object.entries(modelStyleValue.mappedValues)) {
                // When the model and colour datafield both have values populated
                expression.push([
                    "==",
                    true,
                    ["all", ["==", ["get", colourStyleValue.dataFieldId], colourListItemId], ["==", ["get", modelStyleValue.dataFieldId], modelListItemId]],
                ]);
                expression.push(this.createModelId(modelValue.staticValue, customImageId, colourValue.staticValue));
            }
            // When model datafield value is null and colour datafield value is populated
            expression.push(["==", true, ["all", ["==", ["get", colourStyleValue.dataFieldId], colourListItemId], ["==", ["get", modelStyleValue.dataFieldId], null]]]);
            expression.push(this.createModelId(modelStyleValue.staticValue.staticValue, customImageId, colourValue.staticValue));
        }

        for (const [modelListItemId, modelValue] of Object.entries(modelStyleValue.mappedValues)) {
            // When colour datafield value is null and the model datafield value is populated
            expression.push(["==", true, ["all", ["==", ["get", colourStyleValue.dataFieldId], null], ["==", ["get", modelStyleValue.dataFieldId], modelListItemId]]]);
            expression.push(this.createModelId(modelValue.staticValue, customImageId, colourStyleValue.staticValue.staticValue));
        }

        // If neither the model or colour datafield have a value selected
        expression.push(this.createModelId(modelStyleValue.staticValue.staticValue, customImageId, colourStyleValue.staticValue.staticValue));
        return expression;
    }

    /** Depending on what the model style is changes what list item ids we use to create the scale value */
    private getListItemsForDimensionalScaling(modelStyle: ModelLayerStyle) {
        const { height } = modelStyle;
        if (this.isLayerDimensionsBasedOnDataFields(modelStyle)) {
            return Object.keys(height.mappedValues);
        }

        if (this.isLayerDimensionsListItemPropertyBased(modelStyle)) {
            return height.mappedPropertyListItemIds;
        }

        throw new Error("Unhandled datafield based dimensional scaling");
    }
}
