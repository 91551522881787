import { Theme, css } from "@emotion/react";
import { skeletonShimmer } from "./skeleton";

// The minimum padding around the edge of the screen, accounts for common bevels on mobile devices
export const edgePaddingMinimum = "16px";
export const inlineTextIconMargin = "11px";
export const sectionalMargin = "20px";
export const modalPadding = "40px";
export const modalPaddingExtraSmall = "15px";
export const formPadding = "20px";
export const labelMargin = "0.4rem";
export const tableToolBarHeight = "45px";
export const borderRadius = { input: "4px", standard: "5px", signUp: "8px", double: "10px", circle: "50%" };
export const formFieldVerticalGap = "15px";
export const timeInputWidth = "150px";
export const minimumConfirmationDialogWidth = "500px";
export const minimumConfirmationDialogWidthSmallDevices = "250px";
export const formGap = "10px";
export const formButton = {
    height: "44px",
    width: "140px",
};
export const popoverAnchorGap = "8px";
export const inputMarginBottom = "14.931px";
export const popoverPadding = { xsmall: "4px", small: "7.5px", standard: "15px" };
export const inputLeftPadding = "12px";
export const chipGap = "10px";
export const inputHeight = "44px";
export const smallIconButtonSize = "25px";
export const iconButtonSize = "44px";
export const sidebarPadding = "20px";
export const iconWidth = { small: "20px", medium: "30px", large: "44px" };
export const verticalPopoverGap = "5px";
export const closeButton = { height: "16px", width: "12px" };
export const passwordHeaderMargin = "15px";
export const passwordContentMargin = "5px";
export const narrowPadding = "10px";
export const editStyleFormGap = "20px";
export const tileViewGap = "15px";
export const spatialHeaderIconSize = { height: "14px", width: "18px" };
export const loginCenterWidth = "380px";
export const helperTextHeight = "25px";
export const inputHeightPlusHelperHeight = `calc(${inputHeight} + ${helperTextHeight})`;
export const gapWithRoomForErrorMessage = "17px";
export const cardShadow = "0px 8px 20px 0px rgba(81, 82, 101, 0.26)";
/** Matches the mui max modal height on desktops */
export const modalMaxHeight = "calc(100% - 64px)";

export const planPadding = { small: "14px", large: "28px" };
export const toolbarButton = "38px";
export const smallToolbarButton = "28px";
export const toolbarButtonPadding = "5px";
export const toolbarCommentButtonHeight = "34px";
export const toolbarCommentButtonWidth = "36px";
export const toolbarCommentButtonPadding = "5px 6px";
export const fontSizes = {
    /** 50px */
    xxxxLarge: "3.125rem",
    /** 34px */
    xxxLarge: "2.125rem",
    /** 24px */
    xxLarge: "1.5rem",
    /** 20px */
    xLarge: "1.25rem",
    /** 18px */
    large: "1.125rem",
    /** 17px */
    treeNodeLarge: "1.063rem",
    /** 16px */
    medium: "1rem",
    /** 15px */
    treeNodeMedium: "0.938rem",
    /** 14px */
    small: "0.875rem",
    /** 13px */
    summarySmall: "0.813rem",
    /** 12px */
    xSmall: "0.75rem",
    /** 8px */
    xxSmall: "0.5rem",
    /** 6px */
    xxxSmall: "0.375rem",
    /** 4px */
    xxxxSmall: "0.25rem",
} as const;

export const zIndexes = {
    drawingToolbar: 501,
    mapSidebar: {
        root: 800,
        node: "110",
        stickyNode: "111",
        nameSegment: "112",
    },
    dialog: "1290",
    sessionCard: "1280",
    tour: "10000",
    dataTable: {
        selectedRow: "50",
        columnResize: "100",
        columnFilter: "200",
        menu: "300",
    },
    schedule: {
        calendarPopover: "10",
    },
    editLayer: {
        sdfIcon: "100",
    },
    projectCard: {
        interactiveOverlay: 500,
        billingPopover: 501,
    },
    stickyComponents: 1200,
    searchResults: 100,
    routePlanner: 700,
    toast: 2000,
};

export const timeInputMixin = css`
    width: ${timeInputWidth};
`;

export const hiddenTextCss = css`
    .hidden {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        white-space: nowrap;
    }
`;

export const checkboxWithLabelCss = css`
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    label {
        font-size: ${fontSizes.xSmall};
        justify-self: flex-start;
        white-space: nowrap;
        display: flex;
        align-self: center;
    }
`;

export const onHoverCornerButtonCss = ({ theme, selected }: { theme: Theme; selected: boolean }) => css`
    width: ${iconWidth.medium};
    height: ${iconWidth.medium};
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: ${borderRadius.circle};
    padding: 5px;
    :hover {
        background-color: ${theme.shades.four};
    }
    display: ${selected ? "block" : "none"};
    background-color: ${selected ? theme.secondaryColors.light50 : "transparent"};
    border-radius: 2px;
`;

/* Hovering on the parent shows the button, apply this rule to the parent */
export const onHoverCornerButtonParentCss = ({ theme }: { theme: Theme }) => css`
    display: block;
    background-color: ${theme.secondaryColors.light50};
    :hover {
        background-color: ${theme.tertiaryColors.primary};
        color: ${theme.secondaryColors.blank};
    }
`;

export const unauthenticatedOverlayStyle = {
    backgroundColor: "white",
    opacity: 0.9,
};

export const defaultSkeletonMixin = ({ theme }: { theme: Theme }) => css`
    ${skeletonShimmer({ sequence: 0 })({ theme })};
    display: block;
    transform: scale(1, 0.9);
    overflow: hidden;
    width: 100%;
    height: 100%;
    ${skeletonShimmer({ sequence: 0 })({ theme })};
`;

export const contextMenuRules = {
    padding: "8px",
    anchorHorizontalOffset: -18,
    anchorVerticalOffset: 8,
    borderRadius: "4px",
};

export const removeArrowsForInputTypeNumber = css`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

/** Use this when you want the dark blue checkbox */
export const checkboxSubduedMixin = ({ theme }: { theme: Theme }) => css`
    .Mui-checked {
        .MuiSvgIcon-root {
            color: ${theme.primaryColors.subdued70};
        }
    }
`;

/** Used for the minimising the sidebar  */
export const horizontalTransform = "translate3d(-100%, 0, 0)";
export const verticalTransform = "translate3d(0, -100%, 0)";

export const popoverShadow = "0px 2px 6px 0px rgba(0, 0, 0, 0.8)";
