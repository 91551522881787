import GeoJSON from "geojson";
import { AnySupportedGeometry, MapCommentProperties, MapObjectProperties, RouteWaypoint, SelectedMapObject } from "@iventis/map-types";
import { DigitalTwinCameraMode } from "@iventis/domain-model/model/digitalTwinCameraMode";
import { MapMode } from "../machines/map-machines.types";
import { DrawingModifier, SelectedMapComment, StoredBounds, StoredPosition } from "./store-schema";
import { CommentsDrawingModes } from "../utilities/comments-drawing";
import { CompositionMapObject } from "./internal";

export enum MapStoreEventType {
    SET_POSITION = "SET_POSITION",
    REQUEST_GEOMETRY = "REQUEST_GEOMETRY",
    SET_MODE = "SET_MODE",
    SET_OBJECTS = "SET_GEOMETRY",
    CLEAR_DRAWING_OBJECTS = "CLEAR_DRAWING_OBJECTS",
    SET_MAP_OBJECTS_SELECTED = "TOGGLE_MAP_OBJECTS_SELECTED",
    SELECT_ANALYSIS_LAYER = "SELECT_ANALYSIS_LAYER",
    CLEAR_MAP_OBJECTS_SELECTED = "CLEAR_MAP_OBJECTS_SELECTED",
    COMPOSE_NEW_OBJECTS = "COMPOSE_NEW_OBJECTS",
    MAP_MOVE_START = "MAP_MOVE_START",
    MAP_MOVE_END = "MAP_MOVE_END",
    DESELECT_LAYERS = "DESELECT_LAYERS",
    AREA_SELECT_FINISHED = "AREA_SELECT_FINISHED",
    EXIT_ANALYSIS_MODE = "EXIT_ANALYSIS_MODE",
    ENTER_ANALYSIS_MODE = "ENTER_ANALYSIS_MODE",
    DRAG_START = "DRAG_START",
    DRAG_END = "DRAG_END",
    DELETE_COMPOSITION = "DELETE_COMPOSITION",
    DRAWING_FINISHED = "DRAWING_FINISHED",
    OBJECT_PLACEMENT_STARTED = "OBJECT_PLACEMENT_STARTED",
    OBJECT_PLACEMENT_FINISHED = "OBJECT_PLACEMENT_FINISHED",
    LEVEL_CHANGED = "LEVEL_CHANGED",
    DELETE_OBJECTS_REQUEST = "DELETE_OBJECTS_REQUEST",
    UPDATE_ROUTE_WAYPOINTS = "UPDATE_ROUTE_WAYPOINTS",
    SET_EDITING_ROUTE = "SET_EDITING_ROUTE",
    SET_COMMENT_GEOMETRY = "SET_COMMENT_GEOMETRY",
    SELECT_MAP_COMMENT = "SELECT_MAP_COMMENT",
    DELETE_MAP_COMMENT = "DELETE_MAP_COMMENT",
    ENTER_COMMENTS_MODE = "ENTER_COMMENTS_MODE",
    CANCEL_COMMENT = "CANCEL_COMMENT",
    UPDATE_SELECTED_COMMENT_POSITION = "UPDATE_SELECTED_COMMENT_POSITION",
    UPDATE_COMMENTS_DRAWING_MODE = "UPDATE_COMMENTS_DRAWING_MODE",
    SET_DRAWING_MODIFIER = "SET_DRAWING_MODIFIER",
    SET_CAMERA_MODE = "SET_CAMERA_MODE",
    SET_VENUE = "SET_VENUE",
    SET_ALITUDE = "SET_ALITUDE",
}

export type DeleteObjectsEvent = {
    eventType: MapStoreEventType.DELETE_OBJECTS_REQUEST;
};

export type LevelChangedEvent = {
    eventType: MapStoreEventType.LEVEL_CHANGED;
    payload: {
        level: number;
    };
};

export type UpdateWaypointsEvent = {
    eventType: MapStoreEventType.UPDATE_ROUTE_WAYPOINTS;
    payload: {
        objectId: string;
        waypoints: RouteWaypoint[];
    };
};

export type SetEditingRouteEvent =
    | {
          eventType: MapStoreEventType.SET_EDITING_ROUTE;
          payload: true;
          object: SelectedMapObject | CompositionMapObject;
      }
    | {
          eventType: MapStoreEventType.SET_EDITING_ROUTE;
          payload: false;
          object: undefined;
      };

export type SetPositionEvent = {
    eventType: MapStoreEventType.SET_POSITION;
    payload: {
        mapPostion: {
            position: StoredPosition;
            bounds: StoredBounds;
        };
        getSiteMaps: boolean;
        commentCanvasPosition: [number, number] | null;
    };
};

export type RequestGeometry = {
    eventType: MapStoreEventType.REQUEST_GEOMETRY;
    payload: {
        objects: { layerId: string; objectId: string }[];
    };
};

export type SetModeEvent = {
    eventType: MapStoreEventType.SET_MODE;
    payload: {
        mode: MapMode;
    };
};

export type EnterAnalysisModeEvent = { eventType: MapStoreEventType.ENTER_ANALYSIS_MODE };

export type ExitAnalysisModeEvent = { eventType: MapStoreEventType.EXIT_ANALYSIS_MODE };

export type SetMapObjectsSelected = {
    eventType: MapStoreEventType.SET_MAP_OBJECTS_SELECTED;
    payload: {
        mapObjects: SelectedMapObject[];
        from: string | undefined;
    };
};

export interface ObjectUpdate {
    layerId: string;
    objectId: string;
    geometry: AnySupportedGeometry;
    properties: MapObjectProperties;
    waypoints: RouteWaypoint[];
}

export type SetObject = {
    eventType: MapStoreEventType.SET_OBJECTS;
    payload: ObjectUpdate[];
};

export type ClearDrawingObjects = {
    eventType: MapStoreEventType.CLEAR_DRAWING_OBJECTS;
    payload: undefined;
};

export type ComposeNewObject = {
    eventType: MapStoreEventType.COMPOSE_NEW_OBJECTS;
    payload: { objectIds: string[] };
};

export type MapMoveStart = {
    eventType: MapStoreEventType.MAP_MOVE_START;
    payload: undefined;
};

export type DeselectLayer = {
    eventType: MapStoreEventType.DESELECT_LAYERS;
    payload: undefined;
};

export type AreaSelectFinishedEvent = {
    eventType: MapStoreEventType.AREA_SELECT_FINISHED;
    payload: {
        object: {
            objectId: string;
            layerId: string;
            geometry: AnySupportedGeometry;
        };
        cancelled?: boolean;
    };
};

export type ObjectDragStarted = {
    eventType: MapStoreEventType.DRAG_START;
};

export type ObjectDragEnded = {
    eventType: MapStoreEventType.DRAG_END;
};

export type DeleteComposition =
    | {
          eventType: MapStoreEventType.DELETE_COMPOSITION;
          payload: Pick<ObjectUpdate, "objectId" | "layerId">[];
      }
    | {
          eventType: MapStoreEventType.DELETE_COMPOSITION;
          payload: CompositionMapObject[];
          initialCompositionObject: CompositionMapObject;
      };

export type DrawingFinished = {
    eventType: MapStoreEventType.DRAWING_FINISHED;
    features: CompositionMapObject[];
    initialFeatures: CompositionMapObject[] | undefined;
    isAnalysisObject?: boolean;
};

export type MapMoveEnd = {
    eventType: MapStoreEventType.MAP_MOVE_END;
    payload: StoredPosition;
};

export type ObjectPlacementStarted = {
    eventType: MapStoreEventType.OBJECT_PLACEMENT_STARTED;
};

export type ObjectPlacementFinished = {
    eventType: MapStoreEventType.OBJECT_PLACEMENT_FINISHED;
};

export type SetCommentGeometry = {
    eventType: MapStoreEventType.SET_COMMENT_GEOMETRY;
    payload: GeoJSON.Feature<GeoJSON.Point, MapCommentProperties>;
};

export type SelectMapComment = {
    eventType: MapStoreEventType.SELECT_MAP_COMMENT;
    payload: SelectedMapComment;
    isNewComment?: boolean;
};

export type DeleteMapComment = {
    eventType: MapStoreEventType.DELETE_MAP_COMMENT;
    commentId: string;
};

export type EnterCommentsMode = {
    eventType: MapStoreEventType.ENTER_COMMENTS_MODE;
};

export type CancelCommentEvent = {
    eventType: MapStoreEventType.CANCEL_COMMENT;
    selectedCommentId: string;
};

export type MovingCommentEvent = {
    eventType: MapStoreEventType.UPDATE_SELECTED_COMMENT_POSITION;
    payload?: { canvasPosition: [number, number] };
};

export type UpdateCommentsDrawingMode = {
    eventType: MapStoreEventType.UPDATE_COMMENTS_DRAWING_MODE;
    mode: CommentsDrawingModes;
};

export type SetDrawingModifier = {
    eventType: MapStoreEventType.SET_DRAWING_MODIFIER;
    payload: DrawingModifier;
};

export type SelectAnalysisLayer = {
    eventType: MapStoreEventType.SELECT_ANALYSIS_LAYER;
    layerId: string;
    from: string;
};

export type SetCameraModeEvent = {
    eventType: MapStoreEventType.SET_CAMERA_MODE;
    payload: DigitalTwinCameraMode;
};

export type SetVenueEvent = {
    eventType: MapStoreEventType.SET_VENUE;
    payload: string;
};

export type SetAlitudeEvent = {
    eventType: MapStoreEventType.SET_ALITUDE;
    payload: number;
};

export type MapStoreEvent =
    | SetModeEvent
    | EnterAnalysisModeEvent
    | ExitAnalysisModeEvent
    | SetPositionEvent
    | RequestGeometry
    | SetObject
    | SetMapObjectsSelected
    | ComposeNewObject
    | MapMoveStart
    | DeselectLayer
    | AreaSelectFinishedEvent
    | DeleteComposition
    | ObjectDragStarted
    | ObjectDragEnded
    | DrawingFinished
    | MapMoveEnd
    | ObjectPlacementStarted
    | ObjectPlacementFinished
    | LevelChangedEvent
    | DeleteObjectsEvent
    | UpdateWaypointsEvent
    | SetEditingRouteEvent
    | MovingCommentEvent
    | SetCommentGeometry
    | SelectMapComment
    | DeleteMapComment
    | EnterCommentsMode
    | CancelCommentEvent
    | UpdateCommentsDrawingMode
    | SetDrawingModifier
    | SelectAnalysisLayer
    | SetCameraModeEvent
    | SetVenueEvent
    | SetAlitudeEvent;

export enum ModeTransitions {
    LOADED = "LOADED",
}
