export const mapContent = {
    import_layer: {
        title: "Import a layer",
        confirm: "Import layers",
    },
    data_fields: {
        title: "Attributes",
        add: "Add attribute",
        project_data_fields: "Predefined attribute",
        custom_data_field: "Custom attribute",
        type: "Type",
        name: "Attribute name",
        list_name: "List Name",
        list_items: "List items",
        list_item: "List item",
        types: {
            Text: "Text",
            List: "List",
            Number: "Number",
            Date: "Date",
            Tickbox: "Tick box",
            Time: "Time",
            TimeRange: "Time range",
            Image: "Image",
            Hyperlink: "Hyperlink",
            RepeatedTimeRanges: "Date & time ranges",
        },
        data_driven_formula: {
            title: "Create new formula",
            label: "Determine {{ styleProperty }} by",
            addListItem: "Add list item",
            searchListItem: "Search for a list item",
        },
    },
    saved_map_view: {
        default_label: "Make this the plan's default view.",
        default_description: "This defines the thumbnail image of the plan and loads this view for all users when they open the plan.",
        form_header: "Name this saved view",
        name_label: "Saved view name",
        create_saved_view: "Create a saved view",
        create_new_saved_view: "Create a new saved view",
        edit_saved_view: "Edit the saved view",
        move_map_around: "Move the plan around until you're happy with the new view.",
        save_view_when_ready: "When you're ready, tap on 'Save Changes' to save the view.",
        map_default: "plan default",
    },
    create_map: {
        create_map: "Create plan",
        create_a_map: "Create a plan",
        modal_label: "Plan name",
        create_a_new_map: "Create a new plan",
    },
    buttons: {
        new_layer: "New layer",
        import_layer: "Import layer",
        site_map: "Sitemap",
        background: "Background",
        create_layer: "Create layer",
        cancel: "Cancel",
        share_table: "Share table",
        download_table: "Download table",
        toggle_sidebar: "Toggle sidebar",
        columns_displayed: "Columns displayed",
        closePopup: "Close popup",
        openBasemapSelector: "Open basemap selector",
        removeSiteMap: "Remove sitemap from view",
    },
    labels: {
        object: "Object",
        object_id: "Object ID",
        object_name: "Object name",
        layer: "Layer",
        zone: "Zone",
        zones: "Zones",
        client_group: "Client group",
    },
    nav: {
        layers: "layers",
        data_table: "Data table",
        sitemap: "Sitemap",
        background: "Background",
        basemap: "Basemap",
        background_maps: "Background maps",
        selectSitemap: "Select sitemap",
        selectLevel: "Select level",
        search: "Search",
    },
    popup: {
        view_by_layer: "View by layer",
        view_by_data_field: "View by data field",
        view_by_object: "View by object",
        sitemapsAvailableAtThisLocation: "Sitemaps available at this location",
        closeSitemapSelector: "Close sitemap selector",
        sitemapLevels: "Sitemap levels",
        closeLevelSelector: "Close level selector",
        saved_map_views: "Saved views",
    },

    add_layer: {
        create_a_new_layer: "Create a new layer",
        new_layer: "New Layer",
        name_of_layer: "Name of layer",
        layer_style: "Layer style",
        layer_style_desc: "Choose a drawing type or select a style template",
        drawing_type: "Drawing type",
        area: "Area",
        lines: "Lines",
        points: "Points",
        text_labels: "Text labels",
        templates: "Templates",
        shape_and_colour: "Shape and colour",
        line_colour: "Line colour",
        fill_opacity: "Fill opacity",
        preview: "Preview",
        width: "Width",
        offset: "Offset",
        zones: "Zones",
        list_of_options: "List of options",
        layer_design: "Layer Design",
        layer_templates: "Layer Templates",
        view_all_templates: "View All Layer Templates",
        scratch: "Start a layer from scratch",
        save_layer: "Save Layer",
        type_of_layer: "Type of Layer",
        styles: "Styles",
        save_style: "Save Style",
        more: "More",
        Line: "Line",
        Icon: "Icon",
        Point: "Point",
        Area: "Area",
        layer_being_created: "Layer is being created...",
    },
    measurements: {
        unit_of_measurement: "Unit of measurement",
        imperial: "Imperial",
        metric: "Metric",
    },

    sidebar: {
        node_name: "Name needs to be at least 2 characters long",
    },
    import_layers_tooltip: {
        Layer: "Layer already exists in this plan",
    },
    search: {
        search_map: "Search",
        locations: "Locations",
        objects: "Objects",
        search_description: "Search for location, saved view, object",
        saved_map_view: "Saved views",
        no_results: "No results matched your query",
    },
} as const;

export const mapContent2 = {
    data_driven: {
        formula_applied: "Formula applied",
        by_attributes: "By attributes",
        any_others: "Any Others",
    },
    styles2: {
        bold: "Bold",
        regular: "Regular",
        on: "On",
        off: "Off",
        /* ARROWS */
        arrow_spacing: "Arrow spacing",
        arrow_colour: "Arrow colour",
        arrow_size: "Arrow size",
        arrow_opacity: "Arrow opacity",
        /* ICON */
        rotation: "Rotation",
        rotate_icon: "Rotate icon",
        orientation_3d: "3D orientation",
        flat: "Flat",
        raised: "Raised",
        degrees: "Degrees",
        /* MODEL */
        model: "Model",
        type: "Type",
        scale: "Scale",
        choose_model: "Choose Model",
        error_loading_models: "There was an error loading the 3d models",
        /* LINE */
        solid: "Solid",
        dashed: "Dashed",
        type_of_line: "Type of line",
        Line: "Line",
        Area: "Area",
        Point: "Point",
        Icon: "Icon",
        Model: "Model",
        LineModel: "Line of 3D models",
        spacing: "Spacing",
        plural: {
            Icon: "Icons",
            Point: "Points",
        },
    },
    object: {
        deleteTitle: "Delete selected object(s)",
        deleteDescription: "Are you sure you want to delete the selected object(s)?",
        deleteToast: "{{ count }} objects deleted",
        multiple: "Multiple",
        multiple_objects: "Multiple objects",
    },
    controls: {
        draw: "Draw",
        copy: "Copy",
        select: "Select (Active layers)",
        area_select: "Select (All layers)",
        area_select_invalid: "The area drawn needs to have 3 or more points to be valid",
        analysis: "Analysis",
        search: "Search",
        grab: "Grab",
        toggle_3d_terrain: "3D terrain",
        toggle_globe: "Globe view",
        toggle_3d_buildings: "3D buildings",
        zoom_in: "Zoom in",
        zoom_out: "Zoom out",
        reset: "Reset view",
        vr: "Digital Twin",
        comments: "Comments",
        calendar: "Calendar",
        zoom_to_all_objects: "Zoom to all objects",
        no_objects_to_zoom: "No visible objects on plan",
        bookmarks: "Bookmarks",
    },
    analysis: {
        analysis_title: "Analysis tools",
        distance_title: "Distance",
        distance_description: "Measure the distance along a line",
        distance_content: {
            title: "Total distance of reference line on map",
        },
        walk_time_title: "Walk time",
        walk_time_description: "Calculate walk time along a line",
        walk_time_content: {
            title: "Total journey time when walking",
            approx_time: "Approx. {{ speed }} minutes at",
            m_per_second: "m/second",
        },
        flow_rate_title: "Flow rate",
        flow_rate_description: "Calculated flow of people through a gate (line drawn on the map)",
        flow_rate_content: {
            total_width: "Total width of gate (length of line)",
            per_minute_per_hour: "{{ perMinute }} per minute / {{ perHour }} per hour",
            at: "at",
            people_meter_minute: "people / metre / minute",
            fruin_service_levels: "Fruin service levels",
        },
        area_title: "Area",
        area_description: "Measure area within a shape on the map",
        area_content: {
            title: "Total area of reference polygon on map",
        },
        capacity_content: {
            total_area: "Total area",
            per_m_sq: "{{ perMSq }} per m\u00B2",
            at: "at",
            people_meter_sq: "m\u00B2 / person",
            people_ft_sq: "ft\u00B2 / person",
            fruin_service_levels: "Fruin service levels",
        },
        capacity_title: "Capacity",
        capacity_description: "Calculate the holding capacity (people) of an area on the map",
        count_title: "Count",
        count_description: "Count the objects within an area on the map",
        count_content: {
            total: "Total",
            current_level: "Current Level",
            all_levels: "All Levels",
        },
    },
    styles: {
        line_fill: "Line Fill",
        colour: "Colour",
        colours: "Colours",
        opacity: "Opacity",
        blur: "Blur",
        measurement: "Measurement",
        width: "Width",
        offset: "Offset",
        join_and_end: "Ends and Joins",
        join: "Joins",
        end: "Ends",
        arrows: "Arrows",
        text: "Text",
        text_colour: "Text colour",
        text_font: "Text font",
        text_weight: "Text weight",
        text_size: "Text size",
        text_overlap: "Text overlap",
        allow_text_overlap: "Show all",
        no_text_overlap: "Combine",
        text_outline_width: "Text outline width",
        text_outline_colour: "Text outline colour",
        text_opacity: "Text opacity",
        text_content: "Text content",
        outline: "Outline",
        outline_type: "Outline Type",
        outline_colour: "Outline colour",
        outline_opacity: "Outline opacity",
        outline_width: "Outline width",
        outline_blur: "Outline blur",
        no_colour: "No Colour",
        company_palette: "Company Palette",
        type_of_area: "Type of area",
        area_2d: "2D area",
        area_3d: "3D area",
        height: "Height",
        /* POINTS */
        type_of_point: "Type of point",
        point_fill: "Point fill",
        radius: "Radius",
        pitch_alignment: "3D Orientation",
        area_fill: "Area Fill",
        placement: "Placement",
        pitch_alignment_map: "Flat",
        pitch_alignment_viewport: "Raised",
        pitch_alignment_map_desc: "{{ styleType }} lie flat on the map plane",
        pitch_alignment_viewport_desc: "{{ styleType }} will always face the camera",
        /* ICONS */
        icon: "Icon",
        icon_image: "Icon image",
        icon_size: "Size",
        icon_colour: "Colour",
        icon_opacity: "Opacity",
        rotation: "Rotation",
        allow_overlap: "Icon overlap",
        show_all: "Show all",
        combine: "Combine",
        choose_icon_image: "Choose icon image",
        open_icon_selector: "Open icon selector",
        /* LINE MODEL */
        spacing: "Spacing",
        model_offset: "Model offset",
    },
} as const;

export const mapContent3 = {
    assets: {
        MapIcon: "Icon",
        Model: "3D Model",
        none_selected_warning: "You must have a {{ assetType }} selected",
        all_categories: "All categories",
        select: "Select {{ assetType }}",
        search_placeholder: "Search for {{ assetType }}",
    },
    layerTemplates: {
        selectTemplateText: "Search layer templates",
        createCustomLayer: "Create custom layer",
        startFromScratch: "start a new layer from scratch",
        failedToLoadTemplates: "Failed to load layer templates",
        failedToLoadLayer: "Failed to load layer",
        noLayerTemplates: "We can't find any templates with that name. Would you like to start a new layer without using a template instead?",
        searchPlaceholder: "E.g. Transportation",
        allTemplates: "All templates",
        searchResults: "Search results",
    },
    toolbarButtonTooltips: {
        draw_map_object: "Draw",
        undo: "Undo",
        redo: "Redo",
        copy_map_objects: "Copy/Paste",
        draw_analysis_line: "Draw line",
        draw_analysis_polygon: "Draw polygon",
        draw_rectangle: "Draw rectangle",
        draw_point: "Draw point",
        draw_icon: "Draw icon",
        draw_model: "Draw model",
        draw_line_model: "Draw line model",
        snapping_tools: "Snapping settings",
    },
    sidebarButtonTooltips: {
        new_layer: "Create layer from template",
        import_layer: "Import layer(s) from other plans",
        attribute_data_table: "Attribute data table",
    },
    layerOptionsTooltips: {
        layer_information: "Layer information",
        layer_view_options: "Layer view options",
        hide_layer: "Hide layer",
        show_layer: "Show layer",
    },
    backgroundMapSelector: {
        background_map_options: "Background Options",
    },
    sitemaps: {
        no_sitemaps_available: "No sitemaps at this location.\nSitemaps are detailed maps of locations, such as CAD drawings showing the internal layout of a building.\n",
        no_sitemaps_available_2: "to learn more about adding sitemaps",
    },
    drawing_tooltips: {
        title: "Drawing tools",
        enter_edit_mode: "Click here, then click on the map to start drawing",
        enter_edit_mode_no_layers: "To start drawing on the map, first create (or select) a layer using the panel on the left",
        enter_composition_part_1: "Click on the map to add points. Double click or press",
        enter_composition_part_2: "to finish.",
    },
    search_tooltip: {
        set_save_title: "Set this location as default for plan?",
        set_save_content: "The plan will return to this location next time you open it.",
        set_save_save: "Save view",
        set_save_dismiss: "Maybe later",
        save_info_title: "Saved views",
        save_info_content: "You can manage saved plan views or change the default for your plan from the search menu.",
        save_info_got_it: "Got it",
    },
    attributes: {
        allowed_metadata_properties: "Allowed Metadata Properties",
        required: "Required",
        system_datafield_type: "System Datafield Type",
    },
    map_tour: {
        tip_3_location: {
            title: "Find a location",
            content:
                "Here, you can search for places on the plan such as streets, places, or objects you've placed. You can also save important locations to jump to, and set a default for your plan.",
        },
        tip_4_background: {
            title: "Choose your map background",
            content: "Here, you can choose from different map backgrounds, like satellite imagery or local mapping providers. You can also set the default background.",
        },
        tip_5_layer: {
            title: "Create your first layer",
            content: "To start drawing, create a new layer.",
        },
    },
    zoomableValues: {
        fixed: "Fixed",
        by_zoom_level: "By Zoom Level",
        edit_zoom_levels: "Edit zoom levels",
        predefined: "Predefined",
        custom: "Custom",
        hide: "Hide",
        add_zoom_level: "Add new zoom level",
        zoom_options: "Zoom Options",
        zoom_levels: "Zoom Levels",
        street_level: "At street level",
        city_level: "At city level",
        country_level: "At country level",
        world_level: "At world level",
        max_level: "At max zoom level",
    },
    sidebarToolbar: {
        share: "Share Plan",
        print: "Print Plan",
        unlink: "Unlink Layer",
        group: "Group Layers",
        duplicate: "Duplicate Layer",
    },
    units: {
        pixels: "Pixels",
        degrees: "Degrees",
        scale: "Scale",
        meters: "Meters",
    },
} as const;

export const mapContent4 = {
    analysis: {
        analysis_help_title: "How to use the tools",
        analysis_help_description:
            "Use the drawing tools to draw a line or area on the map. With a object selected, expand the sections below to show different types of analysis.",
    },
    sidebarToolbar: {
        delete_layer: "Delete layer",
        remove_layer: "Remove layer",
    },
    sidebarLayers: {
        emptyLayer: "'{{ layerName }}' has no objects yet",
    },
    attributes: {
        namingOrder: "Naming order",
        customerIdentifier: "Customer identifier name",
        list_items: {
            add_another_placeholder: "Add another list option",
            add_first_placeholder: "Add a list option",
        },
        dataDriven: {
            canDo: "Attribute-driven styling",
            noDatafields: "Add attributes to use attribute-driven styling",
            label: "Set using attributes",

            tour: {
                stage_one: {
                    title: "Attribute Driven Styling",
                    content: "You need to add a list attribute before you can style your layer by attribute values",
                },
            },
        },
        predefined_subtitle: "Choose from a set of ready made attributes",
        custom_subtitle: "Create your own attribute such as a custom list or text input box",
        attribute_type: "Attribute type",
        create_new_attribute: "Create a new attribute by choosing a type from the list below and edit it to your requirements",
        edit_attribute: "Edit this attribute by choosing a type from the list below and edit it to your requirements",
        edit_attribute_no_type: "Edit this attribute by using the fields below and edit it to your requirements",
        create_attribute_title: "Create attribute",
        edit_attribute_title: "Edit attribute",
        add_attribute_subtitle: "Add a new attribute by either choosing a predefined one or creating a new one from scratch",
        predefined_info_title: "Predefined attributes",
        predefined_info_text: "Project administrators can manage these in Project Settings",
        predefined_info_link: "Click here to go to 'Project Settings'",
        create_predefined_title: "Create predefined attribute",
    },
    styles: {
        fill: "Fill",
    },
    layer_tour: {
        tip_1_welcome: {
            title: "Customise your layer",
            content: "You can change how your layer looks, and what information (attributes) you want to store when you add objects to the plan.",
        },
        tip_2_name: {
            title: "Layer name",
            content: "Change the name of your layer to help describe what you are adding to the plan",
        },
        tip_3_attributes: {
            title: "Attributes",
            content:
                "Add attributes (optional) to store information about objects on the plan. For example, you can capture types/categories, costs, quantities, dates or anything else you need.",
        },
        tip_4_styles: {
            title: "Styles",
            content: "Change how objects in this layer will look on the plan - such as colours and sizes.",
        },
        tip_5_attribute_styles: {
            title: "Attribute based styling",
            content: "Use attributes to determine how objects look. For example, different 'types/categories' could have different colours.",
        },
        tip_6_save: {
            title: "Save",
            content:
                "When you're done, press save to add the layer to the plan. You can always change these settings later, such as adding more attributes or adjusting the style.",
        },
    },
    formula_summary: {
        remove_formula: "Remove formula",
    },
    layer_information: {
        title: "Layer information",
        owner_map_location: "Owner plan location",
        open_in_folder: "Open in folder",
        created_by: "Created By",
        created_on: "Created On",
        last_modified: "Last Modified",
        permissions: "Permissions",
        view_collaborators: "View and edit collaborators",
        visualiser_overflow: "... {{ nodeCount }} more folders",
        edit_style: "Layer settings",
    },
    edit_layer: {
        add_to_map: "Add to plan",
        customise: "Customise template",
        text_position: "Text position",
        text_offset: "Text offset",
    },
    style_errors: {
        invalid_incrementor_value: "Invalid value. Please enter a value between {{ minValue }} and {{ maxValue }}",
    },
    style: {
        error: "Something went wrong, the layer changes were not saved",
    },
    toolbarButtonTooltips: {
        confirm_action: "Complete drawing",
    },
} as const;

export const mapContent5 = {
    assets: {
        upload_new: "Upload new {{ assetType }}",
    },
    edit_layer: {
        discard_title: "Unsaved changes",
        discard_message: "By clicking yes you are agreeing to discard all the unsaved change made to this layer. Do you still wish to proceed?",
    },
    move: {
        moveTo: "Move to this location",
        setTo: "Set new location",
        tooltip: {
            Map: "Cannot move into a plan",
            Layer: "Cannot move into a layer",
        },
        tooltip_self: "Cannot move a folder into itself",
    },
    fixed_shape: {
        length: "Length",
        width: "Width",
    },
    mapObjectSidebarOptions: {
        hide_object: "Hide Object",
        show_object: "Show Object",
        hide_group: "Hide Group",
        show_group: "Show Group",
    },
    object_details: {
        minimise: "Minimise object details",
        edit_attributes: "Manage attributes",
        object_details: "OBJECT DETAILS",
        object_name: "Object name",
    },
    verticalBarErrors: {
        layer_hidden_drawing: "You cannot edit a layer that is hidden",
        layer_locked_drawing: "You cannot edit a layer that is locked",
        hide_layer_composition: "You cannot hide a layer whilst you are editing it",
        group_composition: "You cannot hide a group whilst you are editing one of it's layers",
    },
    export: {
        /* using Capital M for map here as button sits next to
        /* share map which uses title case
        */
        export_map: "Export Plan",
        export_preview: "Export preview",
        lock_map: "Lock viewport",
        orientation: {
            title: "Page orientation",
            portrait: "Portrait",
            landscape: "Landscape",
        },
        page_size: {
            title: "Page size",
        },
        file_format: {
            title: "File format",
        },
        scale: "Scale",
        infoTooltip: {
            title: "Viewport",
            text: "Click the map to scroll and adjust your viewport then, lock the viewport to save the changes to your editor.",
        },
    },
    styles: {
        customiseColour: "Customise colour",
        scaleText: "Scale icon to fit text",
        textMargin: "Text margin",
        modelVariableSizeError: "Invalid value. Please ensure all values are between {{ minValue }} and {{ maxValue }}",
        dimensions: "Dimensions",
        customDimensions: "Customise models dimensions",
        customColour: "Allow custom colour.",
        customImage: "Allow custom image (aspect ratio optional).",
        widthLengthHeight: "Width, Length, Height",
    },
    permissions: {
        permission_is: "Permission is",
        readonly: "read-only",
        map: "on plan",
        layer: "on layer",
        selection: "on selection",
    },
    layer_overflow: {
        hidden_text: "Layer overflow menu",
        layer_properties: "Layer settings",
        attribute_table: "Attribute table",
        delete_layer: "Delete layer",
        layer_view: {
            display_text: "Layer view",
            view_by_object: "View by object",
            view_by_attribute: "View by attribute",
        },
        remove_from_map: "Remove from plan",
        manage_permissions: "Manage permissions",
    },
    plan: "Plans",
    dashboard: "Dashboard",
    units: {
        feet: "Feet",
    },
    controls: {
        set_pitch: "Tilt the view",
    },
    toolbarButtonTooltips: {
        helpButton: "Help",
        layers: "Back to layers",
    },
} as const;

export const mapContent6 = {
    styles: {
        models: {
            resetHeight: "Reset height",
            resetLength: "Reset length",
            resetWidth: "Reset width",
            attributeModel: "Edit your 3D model by changing the model and the dimensions",
            attributeDimensions: "Edit your 3D model by changing the dimensions",
            determineModel: "Determine model by",
            determineDimensions: "Determine length, width and height by",
        },
    },
    exports: {
        legendPosition: "Legend position",
        top: "Top",
        bottom: "Bottom",
        left: "Left",
        right: "Right",
        layers: "Layers",
        all: "All",
        custom: "Custom",
        selectLayers: "Select layers to export",
        selected: "selected",
        hidden: "(Hidden)",
        none: "None",
    },
    get_map_errors: {
        default: "Something went wrong",
        not_found: "Plan not found",
        click_here_to_browser_maps: "Click here to browse plans",
    },
    add_list_item_attribute: {
        title: "Add column",
        subtitle: "Edit this attribute to your requirements by using the fields below",
        column_name: "Column name",
        column_type: "Column type",
        type: "Property type",
    },
    list_item_properties: {
        additional_properties: "Additional properties",
    },
    contextMenu: {
        import: "Import",
        export: "Export",
        copy_layer: "Copy layer",
        copy_layers: "Copy layers",
        delete_layers: "Delete layers",
        delete_layers_title: "Deleting layers",
        delete_layers_description:
            "One or more of the layers you are trying to delete are linked to another map. They are being removed from this map and you can relink them at any time.",
    },
    copyOrUnlinkLayers: {
        copy: "Copy",
        unlink: "Unlink",
        copying_layers: "Copying layers",
        unlink_layer: "Unlink a layer",
        linked_layers: "Linked layers",
        original_plan: "Original plan",
        helpbase_blurb: {
            click: "Click here",
            link: "Getting started - Knowledge Base (iventis.com)",
            further_info: "for further information.",
        },
        copy_blurb: {
            are_you_sure: "Are you sure you want to copy",
            this_layer: "this layer",
            these_layers: "these layers",
        },
        unlink_info: {
            links_to: "This layer links to",
            changes_not_reflected:
                "By unlinking from it's original plan, you are creating a copy. Any changes made to the original layer will no longer be reflected in your plan.",
        },
        unlink_blurb:
            "You are copying layers linked to different maps. By unlinking from it's original plan, you are creating a copy. Any changes made to the original layer will no longer be reflected in your plan.",
        copy_with_map_objects: "Copy layer{{ pluralise }} with map objects",
    },
    shareMap: {
        title: "Share plan",
        share_with_team: "Share with team",
        share_with_team_details: "Share the plan with team members by moving it into 'Team Workspace'",
        shared_library: {
            title: "Share file with team",
            footer: "Team members can view the file as read-only",
        },
        shared_link: {
            title: "Share Link",
        },
        share_link: "Generate link",
        share_link_details: "Create a temporary, read-only link to share this plan with users who don't have access to Iventis",
        share_link_generated: "This link will expire in 7 days. You can generate a new link at any time.",
    },
    defaultAttribute: {
        add: "Add a default value for attribute",
        default_field_type: "Default {{ dataFieldType }} value",
        tickbox: {
            label: "Default tick box state",
            on: "ON",
            off: "OFF",
        },
    },

    fileUpload: {
        import_title: "Import data to map",
        drop_file: "Drop file to upload",
        supported_extensions: "Supported extensions",
        contact_support: "Contact support for larger files and other extensions",
        choose_file: "Choose file",
        max_file_size: "Maximum file size {{ maxFileSize }}MB",
    },
} as const;

export const mapContent7 = {
    exportGeoJson: {
        exportLayersInfo: "Selected layers are being exported, we'll let you know when it is ready",
    },
    importGeoJson: {
        invalidGeoJson: {
            title: "Invalid file type",
            message: "The file you have uploaded is invalid. Please upload a valid GeoJSON, JSON, GPX or KML/KMZ file.",
        },
        somethingWentWrong: {
            title: "Something went wrong",
            message: "The file type you have uploaded is invalid. Please upload a valid GeoJSON, JSON, GPX or KML/KMZ file.",
        },
        fileTooLarge: {
            title: "File size too large",
            message: "The file you are trying to upload is too large. Maximum file size is {{ size }}.",
        },
        invalidGeometry: {
            title: "Invalid geometry",
            message: "The layer type you are trying to upload has invalid geometry.",
        },
        invalidLayerType: {
            title: "Incompatible layer type",
            message: "You are trying to upload a {{ importType }} layer to an {{ layerType }} layer.",
        },
        invalidCoordinateSystem: {
            title: "Coordinate system not supported",
            message: "Coordinate system is not supported please use {{ coordinateSystem }}",
        },
        reuploadGeoJson: "Supported file extension is {{ fileExtensions }} and maximum file size is up to {{ size }}.",
        mappedAttributeDescription: "Select properties from the file to import as layer attributes (optional).",
        layerAttribute: "Layer attribute",
        type: "Type",
        fieldToImport: "Field to import",
        overwriteExisting: "Overwrite existing",
        importLayer: "Import layer",
    },
    importKML: {
        invalidKML: {
            title: "Invalid KML Data",
            message: "The KML data is invalid, please check your source file and try again.",
        },
        folderSelectLabel: "Default import layers",
        allFolders: "All layers",
    },
    importGpx: {
        invalidGpx: {
            title: "Invalid GPX Data",
            message: "The GPX data is invalid, please check your source file and try again.",
        },
        import_track: "Import track",
        import_route: "Import route",
    },
    analysis_drawing_tooltips: {
        title: "Analysis tools",
        enter_edit_mode: "Use the drawing tools to select map objects and analyse areas, lines etc.",
        enter_edit_mode_link: "Click here to know more about this tool",
    },
    dataTableGrouping: {
        checkbox: "Group data",
    },
    assets: {
        edit: "Edit selected asset",
        delete: "Delete selected asset",
        uploadAsset: "Upload asset",
        deleteTitleIcon: "Delete icon",
        deleteDescriptionIcon: "Are you sure you want to delete this icon?",
        deleteTitleModel: "Delete model",
        deleteDescriptionModel: "Are you sure you want to delete this mode?",
    },
    systemDataFieldNames: {
        MapObjectName: "Map Object Name",
        MapObjectArea: "Area",
        MapObjectLength: "Length",
        Coordinates: "Co-ordinates",
        RouteTime: "Route Time",
        QuantityOfModelsOnLine: "Quantity",
    },
    toolbarButtonTooltips: {
        route: "Route Generator",
    },
    routeGenerator: {
        routeGenerator: "Route Generator",
        distance: "Distance",
        travelTime: "Travel time",
        addStop: "Add stop",
        addRoute: "New route",
        removeStop: "Remove stop",
        search: "Search or click on the map",
    },
    exports: {
        remarks: {
            title: "Custom remarks",
            placeholder: "Insert custom remarks here",
            error: "Remarks is too long",
        },
    },
    styles: {
        modelOffset: "Model offset",
    },
    attributes: {
        imageDescription: "You can only upload 1 image per attribute. You can replace your selection below.",
        imageTitle: "Upload image to attribute",
        noImageSelected: "No file chosen",
    },
    layerTemplates: {
        chosenForYou: "Chosen for you",
    },
    contextMenu: {
        addAttributeMultipleLayers: "Add attribute to all",
    },
    importLayers: {
        folderOutsideMap: "Cannot import a folder from outside of a map",
    },
    delete: {
        multipleTitleMix: "Delete multiple items",
        multipleDescriptionMix: "Deleting a group will delete all the layers and their objects",
        importLayerHint: "Imported layer will be only be removed from the map and will exist in the original plan",
        multipleLayersTitle: "Delete multiple layers",
        multipleLayersDescription: "Deleting multiple layers will delete all of the objects in them too",
        multipleImportLayersTitle: "Remove imported layers",
        singleImportLayersTitle: "Remove imported layer",
        importLayersDescription: "Deleting imported layer will only delete the objects from your map. You can import the layer from the linked map anytime",
        singleLayerTitle: "Delete a layer",
        singleLayerDescription: "Deleting this layer will delete all of the objects in it too",
    },
} as const;

export const mapContent8 = {
    iconStyle: {
        alignment: "Alignment",
        screen: "Screen aligned",
        north: "North aligned",
    },
    mapObjectSelectionHelpers: {
        layerNotVisible: { message: "Selected map object is on a visually hidden layer", cta: "Toggle visibility" },
        objectOnDifferentLevel: { message: "Selected map object is on a different level", cta: "Switch level" },
        layerNotVisibleAndObjectOnDifferentLevel: {
            message: "Selected map object is on a visually hidden layer and on a different level",
            cta: "Toggle visibility and switch level",
        },
    },
    dates: {
        objectDetails: {
            noRangesAvailable: "No ranges",
            rangeAvailable: "1 range",
            rangesAvailable: "{{ rangesAmount }} ranges",
            add: "Add date and time ranges",
            noDataAvailable: "No data available",
        },
        dateRangeAttribute: {
            name: "Name",
            dates: "Dates",
            time: "Time",
        },
        startDate: "Start date",
        attributeName: "Date & Time Range (e.g. Shifts)",
        attributeInfoBox: {
            title: "Example data values",
            infoBlurb:
                "Use this attribute to define the range of dates and times you want the map object to be visible. For example, ‘<strong>John’s morning shift</strong>’ will show between <strong>9:45 AM</strong> and <strong>17:45 PM</strong>. This visibility can also be determined by the selection in the map’s date and time range on the bottom right of the map.",
            johnsMorningShift: "John’s morning shift",
        },
        attributeSettings: "Attribute settings",
        addRange: "Add range",
        mapObjectDateFiltered: "Selected map object is filtered by the date selected",
        mapObjectDateFilteredCta: "Remove the date filter",
        tooltip: {
            title: "Recurring date & time range",
            blurb: "Use this feature to set repeating ranges for a specific event over regularly occurring dates. E.g. Employee shifts",
        },
        calendarTooltip: "Date & Time Map Filter",
    },
    ranges: "ranges",
    digitalTwin: {
        loading: {
            header: "Building your Digital Twin",
            message: "This may take a couple of minutes",
        },
        restarting: {
            header: "Oops!",
            message: "Sorry, something went wrong. We're getting you reconnected to the digital twin...",
        },
    },
    styles: {
        arrows: {
            sameAsLine: "Same as line",
            custom: "Custom",
        },
        customImage: "Custom image",
        customColour: "Custom colour",
        selectImage: "Select custom image",
        customImagePerModel: "You can only upload 1 image per model. You can replace your selection below.",
        customImageAspectRatio: "This image is not the specified aspect ratio of {{ aspectRatio }}. It may appear stretched or squashed.",
    },
    moveLayer: {
        front: "Bring to front",
        back: "Send to back",
    },
    controls: {
        user_location: "User location",
    },
    deleteAttributePrompt: {
        title: "Are you sure you want to delete this attribute?",
        message: "This will remove the attribute data from all the objects in this layer permanently. This action is not reversible.",
    },
    fileUpload: {
        image_comment: "Upload image to comment",
        invalid: "The file you have uploaded is invalid. Please upload a valid .png, .jpg or .jpeg file",
    },
    comments: {
        edit: "Edit comment",
        delete: "Delete comment",
        image: "Add image",
        replaceImage: "Replace image",
    },
    upload_asset: {
        icon: "icon",
        model: "3D model",
        upload_model: "Upload a 3D model",
        update_model: "Update 3D model",
        upload_icon: "Upload an icon",
        update_icon: "Edit icon",
        name: "Name of {{ assetType }}",
        categories: "Categories",
        search_for_categories: "Search for categories",
        select_asset: "Select {{ assetType }}",
        select_file: "Select file",
        file_end: "File must end in",
        or: "or",
        not_support_custom_image: "The model you've upload doesn't support custom images. To see how to enable this, view the article ",
        select_thumbnail: "Thumbnail",
        something_went_wrong: "Something went wrong",
        image_size: "Image size",
        image_style: "Image style",
        image_file: "Image file",
        enable_colour_customisation: "Enable colour customisation",
    },
    permissionTooltip: "You don't have permission to ",
    createLayerOptions: "Create layer options",
    cancelDrawing: "Cancel drawing",
    deleteObject: "Delete Object",
    importNoWebSocketsToast: "Layer(s) have finished importing/copying, refresh to see the layers",
    refresh: "Refresh",
} as const;

export const mapContent9 = {
    dataFieldsInTooltip: {
        title: "Object hover label",
        tooltip: "Choose up to 5 attribute values to show when hovering over a map object.",
        tooltipAttributeTypes: "Supported attribute types are: Text, Number, List and What3Words",
        error: "Something has gone wrong, please contact support if the issue continues",
    },
    tooltipShortcuts: {
        select_object: "Press V",
        draw: "Press D",
        measure: "Press M",
        undo: "Ctrl + Z",
        redo: "Ctrl + Shift + Z",
        comment: "Press C",
        copy_paste: "Ctrl + C / Ctrl + V",
        delete_object: "Press Del",
        cancel_drawing: "Press Esc or Right Click",
        confirm_drawing: "Press Enter or Double Click",
    },
    draw: {
        polygon: "Polygon",
        line: "Line",
        rectangle: "Rectangle",
        routePlanner: "Route planner",
        point: "Point",
        model: "Model",
        icon: "Icon",
    },
    select: {
        select_object: "Select object",
        select_area: "Select objects within area",
    },
    drawRoute: "Draw route",
    drawLineRectangle: "Draw line rectangle",
    layerOptionsTooltips: {
        lock_layer: "Lock layer",
        unlock_layer: "Unlock layer",
        cannot_lock_layer_while_editing: "You cannot lock a layer whilst you are editing it",
    },
    sitemapVersion: {
        perimeter: "Perimeter",
        drawOutsideCADPerimeter: "Cannot draw outside the boundary on this level",
    },
    map_objects: "map objects",
    map_object: "map object",
    plan: "plan",
    map_layer: "map layers",
    layers: "layers",
    layer: "layer",
    layer_data_field: "layer data field",
    layer_geoJson: "layer as geoJSON",
    route_waypoints: "route waypoints",
    map_objects_attribute_values: "map objects attribute values",
    saved_map_view: "saved map view",
    map_object_geometry: "map object geometry",
    map_background: "map background",
    map_sidebar_order: "map sidebar order",
    map_comment: "map comment",
    model: "model",
    snapping: {
        snapToDraw: "Snap to object",
    },
    drawMapObjectTooltip: "Click to add another object to this layer",
    exports2: {
        title: "Title",
        imageUpload: {
            title: "Custom logo",
        },
    },
    digitalTwin: {
        occupiedTable: {
            id: "Server ID",
            status: "Status",
            user: "User",
        },
        status: {
            Occupied: "In Use",
            Available: "Available",
            Starting: "Starting",
            Stopped: "Stopped",
            Error: "Error",
        },
        occupiedPopup: {
            header: "All digital twin servers are currently unavailable",
            message: "Please try again later, or contact {{ supportEmail }} for more information",
        },
    },
    fileUpload: {
        invalid_compression_size: "The file you are trying to upload is too large and could not be compressed.",
    },
} as const;

export const mapContent10 = {
    permissions: {
        noPermission: "You don't have permission to perform this action",
        viewOnly: "View only",
    },
    importFinished: "Import has finished",
    shareMap: "Shared map link has been copied to your clipboard",
    switchLevel: "Switch level",
    removeFilter: "Remove filter",
    styleByProperty: "Set model dimensions using list item property values",
    styleByPropertyTooltip:
        "When enabled, the size of models in this layer will be determined using dimensions from your list item table. Your list items must contain additional, numeric columns for 'Width', 'Length', and 'Height' to use this option. For detailed instructions, refer to our",
    styleByPropertyTooltipLink: "knowledge base article",
    copy: {
        copyTo: "Copy to this location",
        setTo: "Set new location",
        tooltip: {
            Map: "Cannot copy into a plan",
            Layer: "Cannot copy into a layer",
        },
    },
    digitalTwin: {
        cameraModes: {
            orbit: "Orbit Camera",
            walk: "Walk Through",
            fly: "Fly Camera",
        },
    },
    venue_bookmark: "Venue bookmark",
    venue_bookmark_form: {
        save: "Save bookmark",
        name: "Name",
    },
    venue_bookmark_view: {
        bookmarks: "Bookmarks",
        delete_bookmark: "Delete bookmark",
        create_bookmark: "Create bookmark  from current view",
        no_bookmarks: "No bookmarks",
    },
    venue_bookmark_no_venue_error: {
        title: "Unable to create bookmark",
        message: "You cannot create a bookmark away from a Venue",
    },
    controls: {
        toggle_street_names: "Street and place names",
    },
    modelPreview: {
        front_view: "Front view",
        front_left_view: "Front left view",
        capture_thumbnail: "Capture thumbnail",
        preview_info_heading: "Check the preview and adjust the model if necessary ensuring:",
        preview_info_model_appears: "The model appears in the preview as expected",
        preview_info_centered: "The centre of the model intersects with the blue line",
        preview_info_orientation: "The model is oriented correctly and faces fowards when viewing from the front",
        model_not_supported_error: "Model is not supported, try a different file or contact support.",
    },
    modelShowThumbnailUploader: "or upload a file",
    not_support_custom_colour: "The model you've upload doesn't support custom colours. To see how to enable this, view the article ",
};
