/* eslint-disable iventis/filenames */
import { canEdit } from "@iventis/utilities";
import { ActorRef, assign, createMachine, spawn, send } from "xstate";
import { MeasurementScope } from "@iventis/map-types";
import { choose } from "xstate/lib/actions";
import { EngineInterpreter } from "../bridge/engine-generic";
import { CompositionMapObject, MapCursor, TypedFeature } from "../types/internal";
import { isCompositionValid, transformFeatureForNewDrawingPosition } from "../utilities/geojson-helpers";
import { generateCompositionError } from "./mode-machine.helpers";
import { drawingMachine, DRAWING_MACHINE_ID } from "./drawing-machine";
import {
    BeginCompose,
    machineEventTypes,
    ModeContext,
    ModeMachineEventsWithDrawingCallbacks,
    SelectObjectsEvent,
    OptionalDrawingBehaviour,
    DrawingMachineEvents,
    UpdateDrawingGeometryEvent,
    RefreshAttributeListItems,
    UpdateRoute,
    SelectCommentEvent,
    DeleteCommentEvent,
} from "./map-machines.types";
import { isAnalysisLayer, isModifierValid } from "../utilities/state-helpers";
import { FROM_MAP_ENGINE } from "../bridge/constants";
import { MappingEngine } from "../types/store-schema";

/** Returns an action which spawns a drawing machine with the given optional behaviours */
const spawnDrawingMachine = (includeBehaviours: OptionalDrawingBehaviour[], engine: EngineInterpreter, defaultCursor: MapCursor): ActorRef<DrawingMachineEvents> =>
    spawn(
        drawingMachine(engine).withContext({
            defaultCursor,
            includeBehaviours,
        }),
        DRAWING_MACHINE_ID
    );

/** Returns an action which initiates the relevant behaviours for composition */
const enterComposition = (createObjects: (objects: BeginCompose["objects"]) => CompositionMapObject[], engine: EngineInterpreter) =>
    assign({
        defaultCursor: (_, { payload }: { payload: BeginCompose }) => {
            let drawingObjects: CompositionMapObject[];
            if (payload.fromCoordinate === undefined) {
                drawingObjects = createObjects(payload.objects);
            } else {
                drawingObjects = [
                    {
                        geojson: transformFeatureForNewDrawingPosition(payload.objects[0].geojson as TypedFeature, payload.fromCoordinate, payload.direction),
                        objectId: payload.objects[0].objectId,
                        layerId: payload.objects[0].layerId,
                        waypoints: payload.objects[0].waypoints,
                    },
                ];
            }
            engine.startComposition(drawingObjects, payload.duplication, payload.fromCoordinate);
            return MapCursor.COMPOSITION;
        },
    });

export const modeMachine = (engine: EngineInterpreter) =>
    createMachine(
        {
            predictableActionArguments: true,
            id: "modeMachine",
            schema: {
                context: {} as ModeContext,
                events: {} as ModeMachineEventsWithDrawingCallbacks,
                services: {} as {},
            },
            context: {
                drawingMachineRef: undefined,
                defaultCursor: MapCursor.READ,
                layerPermissions: {},
            },
            // eslint-disable-next-line no-undef
            tsTypes: {} as import("./mode-machine.typegen").Typegen0,
            initial: "loading",
            on: {
                ZOOM_IN: {
                    actions: "zoomIn",
                },
                ZOOM_OUT: {
                    actions: "zoomOut",
                },
                SET_PITCH: {
                    actions: "setPitch",
                },
                SET_POSITION: {
                    actions: "setPosition",
                },
                RESET_POSITION: {
                    actions: "resetPosition",
                },
                SET_LAYER_PERMISSIONS: {
                    actions: "setLayerPermissions",
                },
                REFRESH_ATTRIBUTE_LIST_ITEMS: {
                    actions: "refreshattributeListItems",
                },

                // Comment events
                TOGGLE_COMMENTS: [{ target: "edit.addComment", cond: () => !engine.isExternalUser }],
                SELECT_COMMENT: { actions: ["selectComment", "updateDrawingModifier"] },
                DELETE_COMMENT: { actions: "deleteComment" },
                CONFIRM_COMMENT_MOVE: { actions: "confirmCommentMove" },
                CANCEL_COMMENT_MOVE: { actions: "cancelCommentMove" },

                // Selection events
                SELECT_OBJECTS: [
                    {
                        cond: "isAnalysisObject",
                        actions: "selectAnalysisLayer",
                        target: "edit",
                    },
                    {
                        cond: "canEditObjects",
                        target: "edit",
                    },
                    {
                        target: "read",
                    },
                ],
                SELECT_LAYER: [
                    {
                        cond: "canEditVisibleLayer",
                        target: "edit",
                    },
                    {
                        target: "read",
                    },
                ],

                COMPOSE_ANALYSIS: {
                    target: "edit.composition",
                    actions: "selectAnalysisLayer",
                },
                ENTER_AREA_SELECT: {
                    target: "areaSelect",
                },
                ENTER_OBJECT_SELECT: {
                    target: "edit.default",
                },
                ENTER_READ: "#read",
                COMPOSE: [{ cond: "canEditObjects", target: "edit.composition" }],
            },
            states: {
                loading: {
                    on: {
                        LOADED: [
                            {
                                target: "preview",
                                cond: () => engine.isPreview(),
                            },
                            { target: "read" },
                        ],
                        ZOOM_IN: {},
                        ZOOM_OUT: {},
                        RESET_POSITION: {},
                    },
                },
                read: {
                    id: "read",
                    entry: "enterReadMode",
                    exit: "removeReadBehaviour",
                    on: {
                        ENTER_READ: {},
                        PREVIEW: {
                            target: "preview",
                        },
                    },
                },
                preview: {
                    id: "preview",
                    entry: "enterPreviewMode",
                    exit: "exitPreviewMode",
                },
                edit: {
                    initial: "default",
                    on: {
                        UPDATE_ROUTE: {
                            actions: "updateRoute",
                        },
                        SET_DRAWING_MODIFIER: [
                            {
                                cond: "isModifierValid",
                                actions: "updateDrawingModifier",
                            },
                        ],
                    },
                    states: {
                        default: {
                            entry: ["enterEditMode", "spawnEditModeDrawingMachine"],
                            exit: ["exitEditMode", "destroyDrawingMachine"],

                            on: {
                                // DRAWING MACHINE CALLBACKS
                                ROTATION_HAS_STARTED: { actions: "rotationDragStartWhileInEdit" },
                                ROTATION_HAS_ENDED: { actions: "rotationDragEndWhileInEdit" },
                                NODE_DRAG_HAS_STARTED: { actions: "nodeDragStartWhileInEdit" },
                                NODE_DRAG_HAS_ENDED: { actions: "nodeDragEndWhileInEdit" },
                                MIDPOINT_DRAG_HAS_STARTED: { actions: "midpointDragStartWhileInEdit" },
                                MIDPOINT_DRAG_HAS_ENDED: { actions: "midpointDragEndWhileInEdit" },
                                ADD_MIDPOINT: { actions: "addMidpointWhileInEdit" },
                                DELETE_NODE: { actions: "deleteNodeWhileInEdit" },
                                // DRAWING RELATED EVENT HANDLERS THAT DO NOT COME FROM THE DRAWING MACHINE
                                UPDATE_GEOMETRY: { actions: "updateGeometryWhileInEdit" },

                                // STANDARD EVENTS
                                COPY_OBJECTS: {
                                    actions: "copyObjects",
                                },
                                CONTINUE_DRAWING: "composition",
                                DELETE_OBJECT: {
                                    actions: "deleteObjects",
                                },
                            },
                        },

                        addComment: {
                            entry: ["enterCommentsMode", "removeDrawingModifier"],
                            exit: "exitCommentsMode",
                            on: {
                                TOGGLE_COMMENTS: "#modeMachine.read",
                                EXIT_COMMENTS: "#modeMachine.read",
                                // block drawing modifier updates while adding a comment
                                SET_DRAWING_MODIFIER: {},
                                SELECT_OBJECTS: [
                                    {
                                        cond: (_, event: SelectObjectsEvent) => event.payload.from === FROM_MAP_ENGINE,
                                    },
                                ],
                                ADD_MOBILE_COMMENT: { actions: "addCommentUsingMobile" },
                            },
                        },

                        composition: {
                            entry: ["spawnCompositionDrawingMachine", "enterCompositionMode"],
                            exit: choose([
                                { cond: "isCompositionEmpty", actions: ["cancelEmptyDrawing", "exitCompositionMode", "destroyDrawingMachine"] },
                                { cond: "isCancel", actions: ["cancelDrawing", "exitCompositionMode", "destroyDrawingMachine"] },
                                { cond: "compositionIsValid", actions: ["completeDrawing", "exitCompositionMode", "destroyDrawingMachine"] },
                                { actions: ["cancelDrawing", "exitCompositionMode", "destroyDrawingMachine"] },
                            ]),
                            on: {
                                // DRAWING MACHINE CALLBACKS
                                ROTATION_HAS_STARTED: { actions: "rotationStartWhileInComposition" },
                                ROTATION_HAS_ENDED: { actions: "rotationEndWhileInComposition" },
                                NODE_DRAG_HAS_STARTED: { actions: "nodeDragStartWhileInComposition" },
                                NODE_DRAG_HAS_ENDED: { actions: "nodeDragEndWhileInComposition" },
                                ADD_MIDPOINT: { actions: "addMidpointWhileInComposition" },
                                ENTER_HOVER: { actions: "removeCompositionBehaviour" },
                                EXIT_HOVER: { actions: "returnToCompositionBehaviour" },
                                DELETE_NODE: {
                                    actions: "deleteNodeWhileInComposition",
                                },
                                // DRAWING RELATED EVENT HANDLERS THAT DO NOT COME FROM THE DRAWING MACHINE
                                UPDATE_GEOMETRY: {
                                    actions: "updateGeometryWhileInComposition",
                                },

                                // STANDARD EVENTS
                                FINISH: [
                                    {
                                        target: "default",
                                        cond: "compositionIsValid",
                                    },
                                    {
                                        actions: ["generateCompositionError"],
                                    },
                                ],
                                CANCEL_DRAWING: {
                                    target: "default",
                                },
                                // Delete object inside composition mode had the same effect as cancel drawing
                                DELETE_OBJECT: {
                                    target: "default",
                                },
                            },
                        },
                    },
                },
                areaSelect: {
                    entry: ["spawnCompositionDrawingMachine", "enterAreaSelectMode", "removeDrawingModifier"],
                    exit: ["exitAreaSelectMode", "destroyDrawingMachine"],
                    on: {
                        ROTATION_HAS_STARTED: { actions: "rotationStartWhileInComposition" },
                        ROTATION_HAS_ENDED: { actions: "rotationEndWhileInComposition" },
                        NODE_DRAG_HAS_STARTED: { actions: "nodeDragStartWhileInComposition" },
                        NODE_DRAG_HAS_ENDED: { actions: "nodeDragEndWhileInComposition" },
                        ADD_MIDPOINT: { actions: "addMidpointWhileInComposition" },
                        ENTER_HOVER: { actions: "removeCompositionBehaviour" },
                        EXIT_HOVER: { actions: "returnToCompositionBehaviour" },
                        DELETE_NODE: {
                            actions: "deleteNodeWhileInComposition",
                        },
                        // DRAWING RELATED EVENT HANDLERS THAT DO NOT COME FROM THE DRAWING MACHINE
                        UPDATE_GEOMETRY: {
                            actions: "updateGeometryWhileInComposition",
                        },
                        SET_DRAWING_MODIFIER: [
                            {
                                cond: "isModifierValid",
                                actions: "updateDrawingModifier",
                            },
                        ],
                        // STANDARD EVENTS
                        FINISH: {
                            target: "read",
                        },
                        CANCEL_DRAWING: {
                            target: "read",
                        },
                    },
                },
            },
        },
        {
            actions: {
                destroyDrawingMachine: assign({
                    drawingMachineRef: ({ drawingMachineRef }) => {
                        drawingMachineRef?.stop?.();
                        return undefined;
                    },
                }),
                spawnEditModeDrawingMachine: assign({
                    drawingMachineRef: () => spawnDrawingMachine([OptionalDrawingBehaviour.CONTINUE_DRAW, OptionalDrawingBehaviour.OBJECT_DRAG], engine, MapCursor.READ),
                }),
                spawnCompositionDrawingMachine: assign({ drawingMachineRef: () => spawnDrawingMachine([], engine, MapCursor.COMPOSITION) }),
                enterPreviewMode: () => {
                    engine.disableMapCameraMovement();
                },
                exitPreviewMode: () => {
                    engine.enableMapCameraMovement();
                },
                enterCommentsMode: () => engine.enterCommentsMode(),
                exitCommentsMode: (_, event) => engine.leaveCommentsMode(event.type === machineEventTypes.exitComments && event.keepCommentsMachineAlive),
                enterCompositionMode: enterComposition(engine.createDrawingObjects.bind(engine), engine),
                exitCompositionMode: () => engine.finishComposition(),
                enterAreaSelectMode: enterComposition(
                    (objects) => engine.createDrawingObjects.bind(engine)(objects?.map((o) => ({ ...o, geojson: { properties: { measurementScope: MeasurementScope.NEVER } } }))),
                    engine
                ),
                exitAreaSelectMode: (_, event) => engine.finishAreaSelect(event.type !== "FINISH"),
                removeReadBehaviour: () => {
                    engine.removeReadBehaviour();
                },
                generateCompositionError: () => generateCompositionError(engine),
                enterEditMode: (_, event) => {
                    engine.addEditBehaviour(event.type !== machineEventTypes.selectObjects);
                    // If an object being selected has resulted in us transitioning into edit.default, we must make sure we select objects correctly for edit mode
                    if (event.type === machineEventTypes.selectObjects) {
                        engine.selectMapObjectsInEditMode(event.payload.selectedMapObjectIds || [], {
                            updateStore: event.payload.updateStore,
                            from: event.payload.from,
                            isFromDrag: false,
                            allowToggle: !isAnalysisLayer(event.payload.selectedMapObjectIds[0].layerId),
                        });
                    }
                },
                exitEditMode: () => {
                    engine.removeEditBehaviour();
                },
                deleteObjects: (_, event) => {
                    engine.deleteCurrentComposition(event.payload.updateStore);
                },
                updateDrawingModifier: (_, event) => {
                    const selectedLayers = engine.getSelectedLayers();
                    if (event.type === "SET_DRAWING_MODIFIER") {
                        engine.setDrawingModifier(event.payload);
                    }

                    const currentModifier = engine.getCurrentState()?.drawingModifier;
                    if (currentModifier === "none") {
                        return;
                    }

                    // If the modifier is not fit for the current layer selection, then remove the modifier
                    const isValid = isModifierValid[currentModifier](selectedLayers);
                    if (!isValid) {
                        engine.setDrawingModifier("none");
                    }
                },
                removeDrawingModifier: () => {
                    engine.setDrawingModifier("none");
                },
                enterReadMode: (_, event) => {
                    // Bit of a workaround, but when we enter read mode after placing a comment and then drag the comment immediately, it tries to zoom in and results in a weird state.
                    // So preventing the enablement of double click zoom when we re-add read behaviour stops this from happening
                    engine.addReadBehaviour(event.type !== machineEventTypes.exitComments);
                    if (event.type === "CANCEL_DRAWING") {
                        engine.cancelDrawing(true);
                        return;
                    }

                    if (event.type === "SELECT_OBJECTS") {
                        engine.selectMapObjectsInReadMode(event.payload.selectedMapObjectIds || [], {
                            updateStore: event.payload.updateStore,
                            from: event.payload.from,
                        });
                        return;
                    }

                    if (event.type !== "LOADED") {
                        engine.highlightSelectedMapObjects();
                    }
                },
                setLayerPermissions: assign((ctx, event) => ({ ...ctx, layerPermissions: event.payload })),
                // Drawing actions while in composition
                updateGeometryWhileInComposition: (_, event) => {
                    const { features, updateStore } = event.payload;
                    if (features.length !== 1) {
                        throw new Error("We cannot possibly be in composition mode with a non-singular object");
                    }
                    if (engine.areGeometriesOutsideCadBounds(features.map((f) => f.geometry))) {
                        engine.userHasDrawnOutsideCad();
                    } else {
                        engine.compositionSetFeature({ feature: features[0], waypoints: null }, updateStore);
                    }
                },
                deleteNodeWhileInComposition: (_, event) => {
                    const newObject: CompositionMapObject = engine.deleteNodeWhileInComposition(event.payload.coordinate);
                    engine.showRotationHandle([newObject]);
                },
                returnToCompositionBehaviour: () => engine.returnToCompositionBehaviour(),
                removeCompositionBehaviour: () => engine.removeCompositionBehaviour(),
                addMidpointWhileInComposition: (_, event) => engine.addMidpointWhileInComposition(event.payload.point, event.payload.dragging),
                nodeDragEndWhileInComposition: (_, event) => engine.nodeDragFinishInComposition(event.payload.transformed, null, event.payload.isNodeOutSideCadBounds),
                nodeDragStartWhileInComposition: () => engine.nodeDragStartInComposition(),
                rotationEndWhileInComposition: (_, event) => engine.rotationDragFinishInComposition(event.payload),
                rotationStartWhileInComposition: (_, event) => engine.rotationDragStartInComposition(event.payload[0]),
                // Drawing actions while in edit
                rotationDragStartWhileInEdit: (_, event) => engine.rotationDragStartInEdit(event.payload),
                rotationDragEndWhileInEdit: (_, event) => engine.rotationDragFinishInEdit(event.payload),
                nodeDragStartWhileInEdit: (_, event) => engine.nodeDragStartInEdit(event.payload.feature),
                nodeDragEndWhileInEdit: (_, event) => engine.nodeDragFinishInEdit(event.payload.transformed, null, event.payload.isNodeOutSideCadBounds),
                midpointDragStartWhileInEdit: () => engine.midpointDragStartInEdit(),
                midpointDragEndWhileInEdit: (_, event) => engine.nodeDragFinishInEdit(event.payload.transformed, null, event.payload.isNodeOutSideCadBounds),
                addMidpointWhileInEdit: (_, event) => engine.addMidpointWhileInEdit(event.payload.point, event.payload.dragging),
                deleteNodeWhileInEdit: (_, event) => {
                    const newObject: CompositionMapObject = engine.deleteNodeWhileInEdit(event.payload.coordinate);
                    engine.showRotationHandle([newObject]);
                },
                updateGeometryWhileInEdit: (_, event: UpdateDrawingGeometryEvent) => {
                    const { features, updateStore } = event.payload;
                    if (engine.areGeometriesOutsideCadBounds(features.map((f) => f.geometry))) {
                        engine.userHasDrawnOutsideCad();
                    } else {
                        engine.editSetFeatures(features, updateStore);
                    }
                },
                zoomIn: async () => {
                    const pos = await engine.getPosition();
                    engine.setPosition(pos.lat, pos.lng, pos.bearing, pos.pitch, pos.zoom + 1, true);
                },
                zoomOut: async () => {
                    const pos = await engine.getPosition();
                    engine.setPosition(pos.lat, pos.lng, pos.bearing, pos.pitch, pos.zoom - 1, true);
                },
                setPitch: async () => {
                    const pos = await engine.getPosition();
                    engine.setPosition(pos.lat, pos.lng, pos.bearing, pos.pitch > 0 ? 0 : 60, pos.zoom, true);
                },
                setPosition: (_, event) => {
                    const pos = event.payload;
                    engine.setPosition(pos.lat, pos.lng, pos.bearing, pos.pitch, pos.zoom, false, pos.cameraMode, pos.altitude);
                },
                resetPosition: async () => {
                    const pos = await engine.getPosition();
                    engine.setPosition(pos.lat, pos.lng, 0, 0, pos.zoom, true);
                },
                refreshattributeListItems: (_, event: RefreshAttributeListItems) => {
                    engine.refreshAttributeListItems(event.payload.attributeId, event.payload.layerId);
                },
                selectComment: (_, event: SelectCommentEvent) => engine.selectMapCommentExternally(event.payload),
                deleteComment: (_, event: DeleteCommentEvent) => engine.deleteComment(event.commentId),
                addCommentUsingMobile: () => engine.addCommentInMobile(),
                confirmCommentMove: () => engine.confirmCommentMove(),
                cancelCommentMove: () => engine.cancelCommentMove(),
                updateRoute: (_, { payload }: UpdateRoute) => {
                    engine.updateRouteExternal(payload.waypoints, payload.modeOfTransport, payload.updateStore);
                },
                copyObjects: send(() => {
                    const objs = engine.duplicateObjects();
                    return { type: machineEventTypes.compose, payload: { objects: objs, duplication: true } };
                }),
                cancelDrawing: (_, event) => {
                    if (event.type !== machineEventTypes.finish) {
                        const updateStore = "payload" in event && "updateStore" in event.payload ? event.payload.updateStore : false;
                        engine.cancelDrawing(updateStore, event.type === "DELETE_OBJECT");
                    }
                },
                cancelEmptyDrawing: () => {
                    engine.cancelDrawing(true);
                },
                completeDrawing: () => engine.completeDrawing(),
                selectAnalysisLayer: (_, event) => {
                    const layerId = event.type === "SELECT_OBJECTS" ? event.payload.selectedMapObjectIds[0]?.layerId : event.payload.objects[0]?.layerId;
                    if (layerId && isAnalysisLayer(layerId)) {
                        const from = event.type === "SELECT_OBJECTS" ? event.payload.from : FROM_MAP_ENGINE;
                        engine.selectAnalysisLayer(layerId, from);
                    }
                },
            },
            guards: {
                compositionIsValid: () => {
                    // Check if we have a composition - if its null it means we havent started a drawing yet
                    const compositionGeometry = engine.getCurrentComposition(false);
                    return !compositionGeometry.some(({ geojson }) => geojson.geometry.coordinates.length > 0 && !isCompositionValid(geojson.geometry));
                },
                /** Checks if the composition has no points */
                isCompositionEmpty: () => {
                    const compositionGeometry = engine.getCurrentComposition(false);
                    return compositionGeometry.some((comp) => comp.geojson.geometry.coordinates.length === 0);
                },
                canEditVisibleLayer: (ctx, event) => {
                    const isAnalysis = isAnalysisLayer(event.payload.layerId);
                    const layer = engine.getStoredLayer(event.payload.layerId);
                    const canEditLayer = layer?.visible && !layer?.locked;
                    const mappingEngine = engine.getMappingEngine();
                    return mappingEngine === MappingEngine.Mapbox && (isAnalysis || (canEdit(ctx.layerPermissions[event.payload.layerId]) && canEditLayer));
                },
                canEditObjects: (context, event) => {
                    const mappingEngine = engine.getMappingEngine();
                    const objects = event.type === "SELECT_OBJECTS" ? event.payload.selectedMapObjectIds : event.payload.objects;
                    const layerIdToLayer = {};
                    let cannotEdit = false;
                    objects?.forEach((value) => {
                        if (layerIdToLayer[value.layerId] === undefined) {
                            layerIdToLayer[value.layerId] = engine.getStoredLayer(value.layerId);
                            if (layerIdToLayer[value.layerId].locked) {
                                cannotEdit = true;
                            }
                        }
                        if (isAnalysisLayer(value.layerId) || !canEdit(context.layerPermissions[value.layerId])) {
                            cannotEdit = true;
                        }
                    });

                    return objects?.length > 0 && !cannotEdit && mappingEngine === MappingEngine.Mapbox;
                },
                isCancel: (_, event) => event.type !== machineEventTypes.finish,
                isAnalysisObject: (_, event) => isAnalysisLayer(event.payload.selectedMapObjectIds[0]?.layerId),
                isModifierValid: (_, event) => {
                    const selectedLayers = engine.getSelectedLayers();
                    return isModifierValid[event.payload](selectedLayers);
                },
            },
        }
    );
