/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { PayloadAction } from "@reduxjs/toolkit";
import { BehaviorSubject } from "rxjs";
import { Polygon } from "geojson";
import { StyleSpecification } from "@iventis/mapbox-gl";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { Model } from "@iventis/domain-model/model/model";
import { Optional } from "@iventis/utilities";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { DataField } from "@iventis/domain-model/model/dataField";
import { DigitalTwinInstanceStatus } from "@iventis/domain-model/model/digitalTwinInstanceStatus";
import { DigitalTwinCameraMode } from "@iventis/domain-model/model/digitalTwinCameraMode";
import { DigitalTwinInstance } from "@iventis/domain-model/model/digitalTwinInstance";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { Terrain } from "@iventis/domain-model/model/terrain";
import { LocalGeoJson, SelectedMapObject } from "@iventis/map-types";
import { CompositionMapObject, MapCursor } from "./internal";
import { MapMode } from "../machines/map-machines.types";
import { SitemapStyle } from "./sitemap-style";
import type { CommentsDrawingModes, SelectedMapComment } from "../utilities/comments-drawing";

export { SelectedMapComment };

export type DataSourceType = "GeoJSON" | "Coordinates";

export type MapStyleChange = "overlay" | "basemap" | "custom";

export type SelectObjectMethod = "area" | "single" | "addition";

export type DrawingModifier = "snapping" | "none";

export enum SystemLayerType {
    AREA_SELECT = "AREA_SELECT",
    SELECTED_LAYER_AREA_SELECT = "SELECTED_LAYER_AREA_SELECT",
}

export interface GenericPosition {
    lng: number;
    lat: number;
    zoom: number;
    bearing: number;
    pitch: number;
    smooth?: boolean;
    altitude?:number;
}

export interface StoredPosition extends GenericPosition {
    source: Source;
}

export interface UnrealPosition extends GenericPosition {
    altitude: number;
}

export interface StoredBounds {
    bounds: number[][][];
    source: Source;
    duration?: number;
}

export type PitchOptions = { min: number; max: number };

export type LayerId = string;

export type BasicMapLayer = Pick<
    MapLayer,
    | "id"
    | "name"
    | "visible"
    | "locked"
    | "styleType"
    | "areaStyle"
    | "lineStyle"
    | "pointStyle"
    | "iconStyle"
    | "modelStyle"
    | "lineModelStyle"
    | "dataFields"
    | "sidebarOrder"
    | "mapOrder"
>;

// used inside the map module
export interface MapModuleLayer extends BasicMapLayer {
    source: string;
    stamp: string;
    selected: boolean;
    storageScope: LayerStorageScope;
    drawingControls?: LayerDrawingControls;
    remote?: boolean;
    disabled?: boolean;
}

export enum LayerDrawingControl {
    ROTATION_HANDLE = "ROTATION_HANDLE",
    MID_POINT_HANDLE = "MID_POINT_HANDLE",
    COORDINATE_HANDLE = "COORDINATE_HANDLE",
}

export interface LayerDrawingControls {
    [LayerDrawingControl.ROTATION_HANDLE]: boolean;
    [LayerDrawingControl.MID_POINT_HANDLE]: boolean;
    [LayerDrawingControl.COORDINATE_HANDLE]: boolean;
}

export enum LayerStorageScope {
    LocalOnly = "LocalOnly",
    LocalAndTiles = "LocalAndTiles",
}

export enum Source {
    MAP = "Map",
    HOST = "Host",
}

export type PositionUpdatePayload = PayloadAction<StoredPosition>;

export interface TileSource {
    url?: string;
    name: string;
    tiles?: string[];
    type: "vector";
}

export type DrawingObject = Optional<CompositionMapObject, "geojson" | "waypoints">;

export interface DrawingObjects {
    // TODO: Add a method of choosing the location to continue drawing from
    value: DrawingObject[];
    stamp: string;
}

export type ModelData = Model & { modelRequest: Promise<ArrayBuffer> | ArrayBuffer };

export interface MapboxEngineData {
    styles: {
        value: {
            [AssetType.MapBackground]: StyleSpecification;
            [AssetType.SiteMap]: SitemapStyle[];
        };
        stamp: string;
    };
}

export type MapEngineStyle = StyleSpecification;

export type MapboxglStyle = { type: string; style: StyleSpecification };

export interface MapState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    EngineSpecificData = any
> {
    position: { value: StoredPosition; stamp: string };
    // TODO: Decide if this can be multiple
    engineSpecificData: EngineSpecificData;
    layers?: {
        value: MapModuleLayer[];
        stamp: string;
    };
    analysisLayers?: {
        value: MapModuleLayer[];
        stamp: string;
    };
    terrain3D?: {
        value: {
            enabled: boolean;
            exaggeration: number;
            customTerrains: Terrain[];
        };
        stamp: string;
    };
    globe?: {
        value: boolean;
        stamp: string;
    };
    buildings3D?: {
        value: boolean;
        stamp: string;
    };
    streetNames?: {
        value: boolean;
        stamp: string;
    };
    geoJSON?: {
        value: LocalGeoJson;
        stamp: string;
    };
    pendingGeoJSON: {
        /** A mapping of layer id to it's pending geojson object ids, or to string "all" if all are pending */
        value: {
            [layerId: string]: string[] | "all";
        };
        stamp: string;
    };
    mode: {
        value: MapMode;
        stamp: string;
    };
    tileSources: {
        value: Record<"objects" | "comments", { bounds: Polygon | string; tiles: TileSource[] }>;
        stamp: string;
    };
    localToRemoteMapObjectIdsMap: {
        value: { [item in string | number | symbol]: string | number | symbol };
        stamp: string;
    };
    mapObjectsSelected: {
        value: SelectedMapObject[];
        stamp: string;
    };
    commentsSelected: {
        value: SelectedMapComment[];
        stamp: string;
    };
    unitOfMeasurement: {
        value: UnitOfMeasurement;
        stamp: string;
    };
    bounds: {
        value: StoredBounds;
        stamp: string;
    };
    /** When true the user will not be able to interact with the map with no map movements or selecting layers  */
    isCameraMovementLocked: {
        value: boolean;
        stamp: string;
    };
    /** If value is undefined the cursor normally. When specified the map will always have the override cursor value */
    overrideCursor: {
        value: MapCursor;
        stamp: string;
    };
    /** Dates filtering. Filter is if dates filtering is toggled on or off. Day is the epoch seconds value. Time is a combination of hours and mins for example 08:34 -> 834 or 19:30 -> 1930 */
    datesFilter: {
        value: { filter: boolean; day?: number; time?: number };
        stamp: string;
    };
    projectDataFields: DataField[];
    onResize: string;
    mapId: string;
    currentLevel: number;
    hideComments: boolean;
    commentsDrawingMode?: CommentsDrawingModes;
    digitalTwinEngineData: {
        currentDigitalTwinInstance?: DigitalTwinInstance;
        cameraMode: {
            value: DigitalTwinCameraMode;
            stamp: string;
        };
        venueId: string;
        altitude: number;
        status: DigitalTwinInstanceStatus;
    };
    drawingModifier: DrawingModifier;
}

export interface MapStore {
    change: BehaviorSubject<MapState>;
}

export enum MappingEngine {
    Mapbox = "mapbox",
    Unreal = "unreal",
}

export function getMapObjectRemoteId(state: MapState, localId: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const remoteId = state.localToRemoteMapObjectIdsMap.value[localId] as string;
    return remoteId || localId;
}

// Loops through the properties of MapLayer, digs out the properties that point to a style object and turns their values into a union
export type UnionOfStyles = Required<MapLayer> extends Record<string, infer Value> ? (Value extends LayerStyle ? Value : never) : never;

// Loops through the properties of MapLayer, digs out the properties that point to a style object and gets the keys of their values
export type UnionOfStyleProperties = Required<MapLayer> extends Record<string, infer Value> ? (Value extends LayerStyle & Record<infer DeepKey, unknown> ? DeepKey : never) : never;

/** Best used when passing a static style property (e.g. DerivedStyleValue<"colour"> = StyleValue<string>), and will return a StyleValue with the correct fundamental type */
export type DerivedStyleValue<TStyleProperty extends UnionOfStyleProperties> = Required<MapLayer> extends Record<string, infer TStyle>
    ? TStyle extends { [Key in TStyleProperty]: infer TStyleValue }
        ? TStyleValue extends StyleValue<unknown>
            ? TStyleValue
            : never
        : never
    : never;

/** Best used when passing a static style property (e.g. DerivedStyleValueType<"colour"> = string), and will return the correct fundamental type nside the StyleValue */
export type DerivedStyleValueType<TStyleProperty extends UnionOfStyleProperties> = Required<MapLayer> extends Record<string, infer TStyle>
    ? TStyle extends { [Key in TStyleProperty]: infer TStyleValue }
        ? TStyleValue extends StyleValue<infer FundamentalType>
            ? FundamentalType
            : never
        : never
    : never;

export type AttributeDrivenStyleMetaData = {
    value: string;
    listItemId?: string;
    attributeId?: string;
};

export type LayerMetaData = {
    layerName: string;
    layerId: string;
    styleType: StyleType.Model | StyleType.LineModel;
    model: AttributeDrivenStyleMetaData;
    colour: AttributeDrivenStyleMetaData;
};
