import { AssetType } from "@iventis/domain-model/model/assetType";
import { mapInitialState } from "@iventis/map-engine/src/defaults/map-initial-state";
import { MapboxEngineData, MappingEngine } from "@iventis/map-engine/src/types/store-schema";
import { LoadingEvent } from "@iventis/types/loading.types";
import { DomainMapState, MapGlobalState } from "./map.state.types";

export const defaultMappingEngine = MappingEngine.Mapbox;

// TODO: Store should be cleared when the map is navigated away from (reset to empty).
export const initialState: MapGlobalState<MapboxEngineData> = {
    waitingFor: [{ id: "", eventName: LoadingEvent.GET_MAP }],
    incompleteDrawingObjects: [],
    savedMapViewWizard: {
        stage: null,
        mapViewId: null,
    },
    mapName: null,
    savedMapViews: null,
    savedMapViewDefault: null,
    savedBackgroundDefault: null,
    isPlacingGeometry: false,
    selectedBasemap: {
        level: 0,
        [AssetType.MapBackground]: null,
    },
    basemaps: {
        [AssetType.SiteMap]: { value: [], stamp: "" },
        [AssetType.MapBackground]: [],
    },
    sitemapsInView: [],
    levels: [
        {
            index: 0,
            name: "Group Floor",
            abbreviation: "GF",
        },
    ],
    showMapObjectInformation: true,
    mapModule: mapInitialState as DomainMapState,
    signatures: [],
    objectsChangesSaving: [],
    mappingEngine: defaultMappingEngine,
    drawingToolTip: null,
    savedMapTooltip: false,
    isMapPanningAfterSearch: false,
    creatingObjects: [],
    failedToGetMap: null,
    showRoutePlanner: false,
    sitemapConfigs: [],
    selectedAnalysisTool: null,
    showBookmarks: false,
};
